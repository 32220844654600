import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import {
  getScreenSizeWithMaxWidth,
  standarizeThefepiLink,
} from "../../../CommonComponents/Utils";
import StoreSectionFeedModel from "../Models/StoreSectionFeedModel";
import { mixpanelManager } from "../../../Analytics/Mixpanel/Common";
import truncateString from "../Utils/TruncateString";

interface Props {
  model: StoreSectionFeedModel;
  screenWidth: number;
}

export default class StoreContentFeedSectionWidget extends React.Component<Props> {
  render() {
    const model = this.props.model;

    return (
      <div style={{ padding: "24px 0px" }}>
        <div id="StoreProductListSectionFeedSectionTitleContainer">
          {model.contents.topLeadingIconUrl && (
            <div>
              <ImageWidget imageUrl={model.contents.topLeadingIconUrl} />
            </div>
          )}
          <p
            style={{
              textAlign: "left",
            }}>
            {" "}
            {this.props.screenWidth >= 600
              ? model.contents.sectionTitle
              : truncateString(model.contents.sectionTitle ?? "", 25)}{" "}
          </p>
          <button
            onClick={() => {
              mixpanelManager.storeFeedClick({
                type: "button",
                title: "see all",
                group_name:
                  window.location.href.match(/Store\/([a-zA-Z]+)/)?.[1] ||
                  "HOME",
                identifier:
                  this.props.model.identifier || "STORE_CONTENT_FEEDS",
                screen_name: "Store",
              });
              if (model.contents.seeAllDeeplink) {
                window.location.href = standarizeThefepiLink(
                  model.contents.seeAllDeeplink
                );
              }
            }}>
            see all
          </button>
        </div>
        <div>
          <ScrollContainer
            horizontal={true}
            style={{
              display: "flex",
              padding: "12px 16px",
              columnGap: 8,
            }}>
            {model.contents.contentFeeds.map((feed, index) => {
              return (
                <div
                  key={`${index}`}
                  style={{
                    width: getScreenSizeWithMaxWidth().width / 2.4,
                    minWidth: getScreenSizeWithMaxWidth().width / 2.4,
                    borderRadius: 8,
                    boxShadow: "0px 0px 12px rgba(153, 153, 153, 0.25)",
                  }}>
                  <a
                    href={`/Content/${feed.contentId}`}
                    style={{
                      width: getScreenSizeWithMaxWidth().width / 2.4,
                      minWidth: getScreenSizeWithMaxWidth().width / 2.4,
                    }}>
                    <img
                      height={
                        (getScreenSizeWithMaxWidth().width / 2.4) * (3.0 / 2.0)
                      }
                      src={feed.contentImageUrl}
                      alt=""
                      style={{
                        borderRadius: "8px 8px 0px 0px",
                        objectFit: "cover",
                        width: getScreenSizeWithMaxWidth().width / 2.4,
                        minWidth: getScreenSizeWithMaxWidth().width / 2.4,
                      }}
                    />
                  </a>
                  <a
                    href={`/${feed.creatorId}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 8,
                      columnGap: 8,
                    }}>
                    <img
                      src={feed.creatorProfilePicture}
                      alt=""
                      width={24}
                      height={24}
                      style={{ borderRadius: 12, overflow: "hidden" }}
                    />
                    <p
                      style={{ fontSize: 12 }}
                      dangerouslySetInnerHTML={{
                        __html: feed.creatorName.replace(
                          /\\u([0-9A-Fa-f]{4})/g,
                          "&#x$1;"
                        ),
                      }}></p>
                  </a>
                </div>
              );
            })}
          </ScrollContainer>
        </div>
      </div>
    );
  }
}
