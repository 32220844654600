import React, { ReactNode, useEffect, useState } from "react";
import BottomSheet from "./DraggableBottomSheetLibrary";
import "./DraggableBottomSheet.css";

type DraggableBottomSheetProps = {
  isShown?: boolean;
  onClosed?: () => void;
  children?: ReactNode;
  animateOut?: boolean;
};

const DraggableBottomSheet = (props: DraggableBottomSheetProps) => {
  const [isOpen, setIsOpen] = useState(props.isShown || false);

  const close = () => setIsOpen(false);

  useEffect(() => {
    if (!isOpen) {
      props.onClosed?.();
    }
  }, [isOpen, props.animateOut]);

  return (
    <>
      <BottomSheet
        styles={{
          bottomSheet: {
            width: "inherit",
            zIndex: "25",
            animation: props.animateOut
              ? "animateDraggableBottomSheetOut 0.5s"
              : "animateDraggableBottomSheetIn 0.5s",
          },
          dragIndicator: {
            wrap: { display: "none"  },
          },
          window: {
            wrap: {
              borderRadius: "12px 12px 0px 0px",
              maxWidth: "600px",
              height: "95%",
              backgroundColor: "black",
            
            },
            content: {
              paddingBottom: "6px",
            },
          },
        }}
        isOpen={isOpen}
        close={close}>
        {props.children}
      </BottomSheet>
    </>
  );
};

export default DraggableBottomSheet;
