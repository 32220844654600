import ic_search from "../../Assets/Images/ic_search_dark.svg";
import ic_sort from "../../Assets/Images/ic_sort.svg";
import ic_search_coupon from "../../Assets/Images/ic_search_coupon.svg";

import "./SearchProductsViewController.css";

import ViewController from "../../CommonComponents/Base/ViewController";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import OmniSearchViewModel from "./OmniSearchViewModel";
import CuratedProductsSortViewWidget from "../CuratedProducts/Widgets/CuratedProductsSortViewWidget";
import { standarizeThefepiLink } from "../../CommonComponents/Utils";
import SearchDefaultContentWidget from "./Widgets/SearchDefaultContentWidget";
import SearchResultContentWidget from "./Widgets/SearchResultContentWidget/SearchResultContentWidget";
import StoreTopTabbarWidget from "../CommonComponents/Widgets/StoreTopTabbarWidget";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import SearchResultProductWidget from "./Widgets/SearchResultProductWidget/SearchResultProductWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import SearchBarWidget from "./Widgets/SearchBar/SearchBarWidget";
import { RefObject, createRef } from "react";
import { mixpanelManager } from "../../Analytics/Mixpanel/Common";

export default class OmniSearchViewController extends ViewController<
  {},
  OmniSearchViewModel
> {
  endOfPageObserver: IntersectionObserver;
  endOfPageSentinel: RefObject<HTMLDivElement> = createRef();

  constructor(props: {}) {
    super(props, new OmniSearchViewModel());

    if (this.viewModel.fresh) {
      this.viewModel.getPreloading();
    }

    this.endOfPageObserver = new IntersectionObserver(
      this.onEndReached.bind(this)
    );

    mixpanelManager.trackPageView("Search");
    mixpanelManager.buttonClick("search");
  }

  onEndReached: IntersectionObserverCallback = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      if (
        this.viewModel.products.length < this.viewModel.totalOfItems &&
        this.viewModel.selectedTabKey === "products"
      ) {
        this.viewModel.loadProducts();
      }
      if (
        this.viewModel.contents.length < this.viewModel.totalContents &&
        this.viewModel.selectedTabKey === "contents"
      ) {
        this.viewModel.loadContents();
      }
    }
  };

  componentDidUpdate() {
    if (this.endOfPageSentinel.current) {
      this.endOfPageObserver.observe(this.endOfPageSentinel.current);
    }
  }

  componentWillUnmount() {
    this.viewModel.persist();
    if (this.endOfPageSentinel.current) {
      this.endOfPageObserver.disconnect();
    }
  }

  renderEmptyQueryState() {
    const miniBanner = this.viewModel.response?.miniBanner;
    const contents = this.viewModel.response?.contents;

    return (
      <>
        {miniBanner && (
          <a
            id="SearchCouponInfoContainer"
            onClick={() =>
              mixpanelManager.searchDefaultClick(
                "MINI BANNER",
                miniBanner.label
              )
            }
            href={standarizeThefepiLink(miniBanner.deeplink)}
          >
            <img src={ic_search_coupon} alt="" />
            <p>{miniBanner.label}</p>
          </a>
        )}
        {this.viewModel.recentQueries.length > 0 && (
          <>
            <p id="SearchDefaultSectionTitle">Recently Searched</p>
            <div id="SearchProductsRecentSearchGrid">
              {this.viewModel.recentQueries.map((value) => (
                <button
                  key={value}
                  onClick={() => this.viewModel.changeQuery(value)}
                >
                  {value}
                </button>
              ))}
            </div>
          </>
        )}
        {contents &&
          contents.map((content) => (
            <SearchDefaultContentWidget
              key={content.title}
              model={content}
              onHotSearchClicked={(value) => {
                mixpanelManager.searchDefaultClick("hot search", value);
                this.viewModel.changeQuery(value);
              }}
              width={this.state.width}
            />
          ))}
      </>
    );
  }

  render() {
    const searchResultWidth = (this.state.width - 52) / 2;
    const aspectRatio = 1.33;
    return (
      <Scaffold
        topBar={
          <SearchBarWidget
            value={this.viewModel.searchInputValue}
            placeholder="Style, Item, Brand ..."
            onValueChange={(value) => {
              this.viewModel.changeQuery(value);
            }}
          />
        }
        isShowLoading={this.viewModel.isLoadingPreloading}
        showErrorModel={this.viewModel.errorMessage}
        bottomModalChild={
          this.viewModel.sorts.length > 0 ? (
            <CuratedProductsSortViewWidget
              models={this.viewModel.sorts}
              selectedId={this.viewModel.selectedSortIdInSortWidget ?? ""}
              onSelectSortId={(value) => this.viewModel.changeSort(value)}
              onApplySortTapped={() => this.viewModel.applySort()}
            />
          ) : undefined
        }
        isShowingBottomModal={this.viewModel.isShowingSort}
        onBottomModalBackgroundTapped={() => this.viewModel.hideSort()}
      >
        <div id="OmniSearchContainer">
          {this.viewModel.query.length === 0 && this.renderEmptyQueryState()}
          {this.viewModel.query.length > 0 && (
            <>
              <SizedDiv height={12} />
              <StoreTopTabbarWidget
                fullWidth
                selectedId={this.viewModel.selectedTabKey}
                labels={[
                  {
                    id: "products",
                    label: "Products",
                    onTap: () => {
                      this.viewModel.onTabKeyChange("products");
                    },
                  },
                  {
                    id: "contents",
                    label: "Contents",
                    onTap: () => {
                      this.viewModel.onTabKeyChange("contents");
                    },
                  },
                ]}
              />
            </>
          )}
          {this.viewModel.query.length > 0 &&
            this.viewModel.selectedTabKey === "contents" && (
              <>
                <SearchResultContentWidget
                  accounts={this.viewModel.accounts}
                  shouldShowSeeMoreLink={
                    this.viewModel.shouldShowSeeMoreAccountsLink
                  }
                  query={this.viewModel.query}
                  stylesHeight={(this.state.width / 2) * aspectRatio}
                  styles={this.viewModel.contents}
                  seeMoreHref={`/Sellers?query=${this.viewModel.query}`}
                />
                {this.viewModel.totalContents >
                  this.viewModel.contents.length && (
                  <div ref={this.endOfPageSentinel}>
                    <LoadingWidget />
                  </div>
                )}
              </>
            )}
          {this.viewModel.query.length > 0 &&
            this.viewModel.selectedTabKey === "products" && (
              <>
                <SearchResultProductWidget
                  seeMoreHref={`/Brands?query=${this.viewModel.query}`}
                  productsCardWidth={searchResultWidth || 0}
                  sortLabel={
                    this.viewModel.sorts?.find(
                      (value) => value.id === this.viewModel.sortId
                    )?.label ?? ""
                  }
                  sortId={this.viewModel.sortId!!}
                  products={this.viewModel.products}
                  shouldShowSeeMoreBrandsLink={
                    this.viewModel.shouldShowSeeMoreBrandsLink
                  }
                  brands={this.viewModel.brands}
                  onSortButtonClick={() => {
                    this.viewModel.showSort();
                  }}
                />
                {this.viewModel.totalOfItems >
                  this.viewModel.products.length && (
                  <div ref={this.endOfPageSentinel}>
                    <LoadingWidget />
                  </div>
                )}
              </>
            )}
        </div>
      </Scaffold>
    );
  }
}
