import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
import "./OrderAndDelivery.css";
import ViewController from "../../CommonComponents/Base/ViewController";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import OrderAndDeliveryViewModel from "./OrderAndDeliveryViewModel";

import PopupComingSoonWidget from "../../CommonComponents/UI/PopupComingSoon/PopupComingSoonWidget";
import StoreTopTabbarWidget from "../../Store/CommonComponents/Widgets/StoreTopTabbarWidget";
import OrderListItemWidget from "./Widget/OrderListItemWidget";
import { OrderType } from "./Models/OrderAndDeliveryResponseModel";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import PopupTrackingWidget from "./Widget/PopupTrackingWidget";
import { DivWithIntersectionObserver } from "../../CommonComponents/UI/DivWithIntersectionObserver/DivWithIntersectionObserver";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import mixpanel from "mixpanel-browser";
import { isResellerDomain } from "../../CommonComponents/Utils";

export default class OrderAndDeliveryViewController extends ViewController<
  {},
  OrderAndDeliveryViewModel
> {
  constructor(props: {}) {
    super(props, new OrderAndDeliveryViewModel());

    this.viewModel.loadPreloadingProps();
    this.viewModel.loadList();
    this.onEndReached = this.onEndReached.bind(this);

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "MyOrder",
    });
  }

  renderCommonHeader(title?: string) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <button
          onClick={() => {
            window.history.back();
          }}
        >
          <svg
            width="10"
            height="18"
            viewBox="0 0 10 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 17L1 9L9 1"
              stroke="black"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <p
          style={{
            marginLeft: "16px",
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "30px",
            display: "flex",
            alignItems: "center",

            color: "#000000",
          }}
        >
          {title}
        </p>
      </div>
    );
  }

  onEndReached() {
    this.viewModel.orders.length > 0 && this.viewModel.onEndReached();
  }

  render() {
    return (
      <Scaffold
        backgroundColor="#F2F2F2"
        topBar={this.renderCommonHeader("My Order")}
        isShowLoading={this.viewModel.isLoading}
        creatorId={this.viewModel.creatorId}
        isCreator={this.viewModel.isCreator}
        showErrorModel={this.viewModel.errorMessage}
      >
        <PopupTrackingWidget
          dataTracking={this.viewModel.trackingData || null}
          isEnabled
          isShowLoadingTracking={this.viewModel.isLoadingTracking || false}
          closeModal={() => {
            this.viewModel.closeTrackingModal();
          }}
          showModal={this.viewModel.shouldShowTrackingModal}
        />
        <div>
          <div
            style={{
              backgroundColor: "#fff",
              position: "fixed",
              top: 52,
              width: 600,
            }}
          >
            <SizedDiv height={8} />
            {this.viewModel.tabs.length > 0 && (
              <StoreTopTabbarWidget
                selectedId={this.viewModel.activeCategoryId || "ALL"}
                labels={this.viewModel.tabs.map((t) => ({
                  ...t,
                  onTap: () => {
                    this.viewModel.changeCategoryId(t.id);
                  },
                }))}
              />
            )}
          </div>

          <SizedDiv height={40} />
          {this.viewModel.orders.map((o) => (
            <>
              <SizedDiv height={16} />
              <OrderListItemWidget
                onOpenChatClick={() => {
                  window.open(o.whatsappUrl, "_blank");
                }}
                attribute={o.product.attribute}
                badgeUrls={o.badgeUrls}
                key={o.orderId}
                moreInfoLabel={o.moreInfo}
                orderDateLabel={o.timeLabel}
                productName={o.product.name}
                productAttribute={o.product.attribute}
                productBrandName={o.product.brand}
                secondStatus={o.secondStatus}
                productId={o.product.productId}
                totalOrderValue={o.subTotal}
                orderNumberLabel={o.orderId}
                status={o.status as OrderType}
                productImageUrl={o.product.imageUrl}
                type={o.type}
                bagId={o.product.bagItemId}
                reviewPoint={o.reviewPoint}
                warningMessage={o.warning}
                promotionalMessage={`${o.postFeedCommission}`}
                createContentCommisionBonusPercentage={o.postFeedCommission}
                createContentPointsBonus={o.postFeedPoint}
                onOrderDetailClick={() => {
                  window.location.href = `/OrderDetail/${o.orderId}`;
                }}
                onWriteReviewClick={() => {
                  window.location.href = `/ToReview?id=${o.orderId}`;
                }}
                onReorderClick={() => {
                  this.viewModel.reAddOrderToCart(o.orderId);
                }}
                onTrackMyOrderClick={(x) => {
                  window.location.href = `/OrderDetail/${o.orderId}?focus=${x}`;
                }}
                isReAddingToCart={o.isReAddingToCart}
                onPaymentInfoClick={() => {
                  window.location.href = `/OrderDetail/${o.orderId}`;
                }}
              />
            </>
          ))}
          <SizedDiv height={12} />
          {this.viewModel.orders.length < this.viewModel.totalItems && (
            <DivWithIntersectionObserver
              onVisibilityChanged={this.onEndReached}
            >
              <LoadingWidget />
            </DivWithIntersectionObserver>
          )}
          <SizedDiv height={12} />
        </div>
      </Scaffold>
    );
  }
}
