import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent";

import Scaffold, {
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";

import RegisterProfileViewModel from "./RegisterProfileViewModel";

import { Navigate } from "react-router-dom";
import "./Register.css";

import ButtonWidget, {
  ButtonWidgetType,
} from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

import InputFieldWidget from "../../CommonComponents/UI/InputFieldWidget/InputFieldWidget";
import mixpanel from "mixpanel-browser";
import { isResellerDomain } from "../../CommonComponents/Utils";

export default class RegisterProfileScreen extends BaseUIComponent<
  {},
  RegisterProfileViewModel
> {
  constructor(props: {}) {
    super(props, new RegisterProfileViewModel());

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "RegisterProfileForm",
    });
  }

  handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onUsernameChanged(event.target.value);
  };

  handleChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onPhoneNumberChanged(event.target.value);
  };

  handleChangeDOB = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onDOBChanged(event.target.value);
  };

  handleSubmit = (event: any) => {
    this.viewModel().onSubmitProfile();
  };

  render() {
    if (this.viewModel().isSuccess) {
      return <Navigate to="/LoginSso" />;
    }

    return (
      <Scaffold
        showSnackbar={
          this.viewModel().errorMessage
            ? new ShowSnackbarModel({
                message: this.viewModel().errorMessage ?? "",
              })
            : undefined
        }
      >
        <div id="RegisterContainer" className="container">
          <div className="row">
            <div className="col">
              <InputFieldWidget
                title="Username"
                inputType="text"
                isEnabled={true}
                placeholder={"Your Username"}
                errorMessage={this.viewModel().errorMessageUsername}
                action={(event) => {
                  this.handleChangeUsername(event);
                }}
              ></InputFieldWidget>
            </div>
          </div>
          <div className="row">
            <div className="col mtop22">
              <InputFieldWidget
                title="Phone Number"
                inputType="number"
                isEnabled={true}
                placeholder={"Your Phone Number"}
                errorMessage={this.viewModel().errorMessagePhoneNumber}
                action={(event) => {
                  this.handleChangePhoneNumber(event);
                }}
              ></InputFieldWidget>
            </div>
          </div>
          <div className="row">
            <div className="col mtop22">
              <InputFieldWidget
                title="DOB"
                inputType="date"
                isEnabled={true}
                placeholder={"Select Date"}
                errorMessage={this.viewModel().errorMessageDOB}
                action={(event) => {
                  this.handleChangeDOB(event);
                }}
              ></InputFieldWidget>
            </div>
          </div>
          <div className="row">
            <div className="col mtop22 text-center">
              <ButtonWidget
                buttonType={ButtonWidgetType.primary}
                isLoading={
                  this.viewModel().isLoadingButtonSubmit ? true : false
                }
                isEnabled={
                  this.viewModel().username === "" &&
                  this.viewModel().phone_number === "" &&
                  this.viewModel().dob === ""
                    ? false
                    : true
                }
                width="calc(100% - 6px)"
                action={() => {
                  this.handleSubmit("");
                }}
              >
                Next
              </ButtonWidget>
            </div>
          </div>
          <div className="row">
            <div className="col mtop35 text-center">
              <a href="/LoginSso">SKIP FOR NOW</a>
            </div>
          </div>
        </div>
      </Scaffold>
    );
  }
}
