import "./ShowCaseHome.css";
import ViewController from "../../CommonComponents/Base/ViewController";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import {
  getLocationQueries,
  getScreenSizeWithMaxWidth,
  isResellerDomain,
} from "../../CommonComponents/Utils";
import ShowCaseHomeViewModel from "./ShowCaseHomeViewModel";
import { ShowCaseHomeCoverWidget } from "./Widgets/ShowCaseHomeCoverWidget";
import ShowCaseHomeFeedWidget from "./Widgets/ShowCaseHomeFeedWidget";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";

import ic_cart from "../../Assets/Images/ic_cart_white.svg";
import ic_search from "../../Assets/Images/ic_search.svg";
import mixpanel from "mixpanel-browser";

export class ShowCaseHomeViewController extends ViewController<
  {},
  ShowCaseHomeViewModel
> {
  constructor(props: {}) {
    super(props, new ShowCaseHomeViewModel());

    this.viewModel.loadPreloadingProps();

    window.addEventListener("scroll", () => this.handleScrolling());

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "Showcase",
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => this.handleScrolling());
  }

  render() {
    const response = this.viewModel.response;
    const cover = this.viewModel.cover;
    const feeds = this.viewModel.feeds;

    return (
      <Scaffold
        selectedBottomBarItem={
          getLocationQueries()["topSafeAreaMargin"] ? undefined : "showcase"
        }
        creatorId={this.viewModel.creatorId}
        isCreator={this.viewModel.isCreator}
        isShowLoading={this.viewModel.isLoadingPreloading}
        showErrorModel={this.viewModel.errorMessage}
      >
        <div
          id="ShowCaseHomeMainContainer"
          onScroll={() => this.handleScrolling()}
        >
          <div
            id="ShowCaseDetailTopBar"
            style={{
              height: `${getLocationQueries()["topSafeAreaMargin"] ?? 60}px`,
            }}
          >
            <div id="ShowCaseDetailTopBarBackground" />
            {!getLocationQueries()["topSafeAreaMargin"] && (
              <>
                <p id="ShowCaseHomeTitle">Showcase</p>
                <div className="FlexFillSpacing"></div>
                <NavigationButton
                  src={ic_search}
                  onClick={() => {
                    window.location.href = "/Store/Search";
                  }}
                />
                <NavigationButton
                  src={ic_cart}
                  numberOnRightTop={this.viewModel.numberInCart}
                  onClick={() => {
                    window.location.href = "/Cart";
                  }}
                />
              </>
            )}
          </div>
          {response && (
            <>
              <ShowCaseHomeCoverWidget model={cover} />
              {feeds.map((feed, index) => (
                <ShowCaseHomeFeedWidget
                  key={`ShowCaseHomeCoverWidget_${index}`}
                  model={feed}
                />
              ))}
            </>
          )}
          {this.viewModel.totalOfFeeds > this.viewModel.feeds.length && (
            <div id="ShowCaseHomeLoadingContainer">
              <LoadingWidget />
            </div>
          )}
        </div>
      </Scaffold>
    );
  }

  private handleScrolling() {
    const loadingContainerElement = document.querySelector(
      "#ShowCaseHomeLoadingContainer"
    );
    const loadingContainerRect =
      loadingContainerElement?.getBoundingClientRect();
    const screenSize = getScreenSizeWithMaxWidth();
    const coverHeight = screenSize.width;

    if (
      !this.viewModel.isLoadmoreFeeds &&
      this.state.height - (loadingContainerRect?.y ?? 0) > -200.0 &&
      this.viewModel.feeds.length < this.viewModel.totalOfFeeds
    ) {
      this.viewModel.loadMoreFeeds();
    }

    const coverElement = document.getElementById("ShowCaseHomeMainContainer");
    const topNavBarBackgroundElement = document.getElementById(
      "ShowCaseDetailTopBarBackground"
    );
    const showcaseHomeTitleElement =
      document.getElementById("ShowCaseHomeTitle");

    const coverYPosition = coverElement?.getBoundingClientRect().y ?? 0.0;
    const percentage = -(coverYPosition / coverHeight);

    (topNavBarBackgroundElement as any).style.opacity =
      percentage > 1 ? 1 : percentage;
    (showcaseHomeTitleElement as any).style.opacity = percentage > 0.5 ? 1 : 0;
  }
}
