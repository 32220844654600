import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { standarizeThefepiLink } from "../../../CommonComponents/Utils";
import { SearchDefaultContentModel } from "../models/PreloadingResponse";
import { mixpanelManager } from "../../../Analytics/Mixpanel/Common";

interface Props {
  model: SearchDefaultContentModel;
  onHotSearchClicked: (query: string) => void;
  width: number;
}

export default class SearchDefaultContentWidget extends React.Component<Props> {
  render() {
    const model = this.props.model;
    const type = model.type;

    switch (type) {
      case "CATEGORIES":
        return (
          <>
            <p id="SearchDefaultSectionTitle">{model.title}</p>
            <ScrollContainer
              style={{
                padding: "12px 24px 40px",
                display: "flex",
                columnGap: 16,
              }}
            >
              {model.items.map((item) => (
                <a
                  onClick={() =>
                    mixpanelManager.searchDefaultClick("categories", item.label)
                  }
                  href={standarizeThefepiLink(item.deeplink ?? "")}
                >
                  <img
                    src={item.imageUrl}
                    alt=""
                    style={{ height: 244, width: 244 }}
                  />
                  <p id="SearchDefaultSectionCategoryTitle">{item.label}</p>
                </a>
              ))}
            </ScrollContainer>
          </>
        );
      case "HOT_SEARCH":
        const halfHotSearchCount = Math.ceil(model.items.length / 2);
        return (
          <>
            <p id="SearchDefaultSectionTitle">{model.title}</p>
            <div
              style={{
                margin: "0px 24px",
                display: "flex",
                columnGap: 8,
              }}
            >
              <div id="SearchProductsHotSearchListContainer">
                {model.items
                  .slice(0, halfHotSearchCount)
                  .map((value, index) => (
                    <>
                      <button
                        onClick={() =>
                          this.props.onHotSearchClicked(value.label)
                        }
                      >
                        <p>{index + 1}</p>
                        <p>{value.label}</p>
                      </button>
                    </>
                  ))}
              </div>
              <div id="SearchProductsHotSearchListContainer">
                {model.items.slice(halfHotSearchCount).map((value, index) => (
                  <>
                    <button
                      onClick={() => this.props.onHotSearchClicked(value.label)}
                    >
                      <p>{index + 1 + halfHotSearchCount}</p>
                      <p>{value.label}</p>
                    </button>
                  </>
                ))}
              </div>
            </div>
          </>
        );
      case "POPULAR_BRANDS":
        const popularBrandCellWidth = (this.props.width - 96) / 4;

        return (
          <>
            <p id="SearchDefaultSectionTitle">{model.title}</p>
            <div id="SearchDefaultPopularBrandContainer">
              {model.items.map((value) => (
                <a
                  style={{ width: popularBrandCellWidth }}
                  onClick={() => {
                    mixpanelManager.brandClick(
                      standarizeThefepiLink(value.deeplink ?? "").split("/")[2],
                      value.label,
                      "Search"
                    );
                    mixpanelManager.searchDefaultClick(
                      "popular brands",
                      value.label
                    );
                  }}
                  href={standarizeThefepiLink(value.deeplink ?? "")}
                >
                  <div
                    style={{
                      width: popularBrandCellWidth,
                      height: popularBrandCellWidth,
                      borderRadius: popularBrandCellWidth / 2,
                      overflow: "hidden",
                    }}
                  >
                    <ImageWidget imageUrl={value.imageUrl} />
                  </div>
                  <div>
                    <p>{value.label}</p>
                  </div>
                </a>
              ))}
            </div>
          </>
        );
      case "INSPIRATION":
        const inpirationCellWidth = (this.props.width - 96) / 3;

        return (
          <>
            <p id="SearchDefaultSectionTitle">{model.title}</p>
            <div id="SearchDefaultInspirationContainer">
              {model.items.map((item) => (
                <a
                  onClick={() =>
                    mixpanelManager.searchDefaultClick(
                      "inspiration",
                      item.label
                    )
                  }
                  href={standarizeThefepiLink(item.deeplink ?? "")}
                  style={{
                    width: inpirationCellWidth,
                    height: inpirationCellWidth,
                  }}
                >
                  <div
                    style={{
                      width: inpirationCellWidth,
                      height: inpirationCellWidth,
                      borderRadius: inpirationCellWidth / 2,
                      overflow: "hidden",
                    }}
                  >
                    <ImageWidget imageUrl={item.imageUrl} />
                  </div>
                  <div>
                    <p>{item.label}</p>
                  </div>
                </a>
              ))}
            </div>
          </>
        );
      default:
        return <></>;
    }
  }
}
