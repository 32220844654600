import "./AddressSelector.css";

import ViewController from "../../CommonComponents/Base/ViewController";
import ButtonWidget from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import AddressSelectorViewModel from "./AddressSelectorViewModel";
import AddressSelectorLocationCell from "./Widgets/AddressSelectorLocationOptionCell";
import {
  WithCreatorLinkProps,
  getRawLocationQueriesString,
  isResellerDomain,
  withSellerId,
} from "../../CommonComponents/Utils";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import { mixpanelManager } from "../../Analytics/Mixpanel/Common";

class AddressSelectorViewController extends ViewController<
  WithCreatorLinkProps,
  AddressSelectorViewModel
> {
  constructor(props: {}) {
    super(props, new AddressSelectorViewModel());

    mixpanelManager.trackPageView("ShippingAddress");
  }

  componentDidMount() {
    this.viewModel.loadList();
  }

  render() {
    return (
      <div id="AddressSelectorVC">
        <Scaffold
          backgroundColor="var(--washoutGrey)"
          topBar={
            <>
              <div
                className="FlexFillSpacing"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "8px",
                  zIndex: "20",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Shipping Address
              </div>
            </>
          }
          showSnackbar={this.viewModel.snackbarError}
          isShowLoading={this.viewModel.isLoading}
          showErrorModel={
            this.viewModel.errorMessage
              ? {
                  message: this.viewModel.errorMessage,
                  onRetryButtonTapped: () => this.viewModel.loadList(),
                }
              : undefined
          }
        >
          <div id="AddressSelectorMainContainer">
            <div>
              <p style={{ marginBottom: 16 }}>Recent Address</p>
              {this.viewModel.addressList.map((model, index) => {
                return (
                  <AddressSelectorLocationCell
                    key={index}
                    id={model.shippingAddressId}
                    name={model.name}
                    address={model.address}
                    phone={model.phone}
                    isSelected={this.viewModel.selectedIndex === index}
                    hasPinPoint={model.hasPinpoint}
                    onClick={() => {
                      this.viewModel.emit(
                        () => (this.viewModel.selectedIndex = index)
                      );
                    }}
                    onDeleteButtonClicked={() => {
                      this.viewModel.deleteAddress(
                        model.shippingAddressId,
                        index
                      );
                    }}
                  />
                );
              })}
              <ButtonWidget
                backgroundColor="var(--purple)"
                buttonHeight={42}
                action={() => {
                  window.location.href = `${
                    isResellerDomain() ? `/shop/${this.props.creatorLink}` : ""
                  }/Checkout/AddressUpdater?${getRawLocationQueriesString()}`;
                }}
              >
                + Add New Address
              </ButtonWidget>
              <div style={{ flexShrink: 0 }}>
                <SizedDiv height={72} />
              </div>
            </div>
            <div
              style={{
                position: "fixed",
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
                bottom: 0,
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  padding: "12px 24px",
                  boxShadow: "0px 0px 22px rgb(0, 0, 0, 0.2)",
                  maxWidth: 600,
                  width: "100%",
                }}
              >
                <ButtonWidget
                  isEnabled={this.viewModel.checkIsConfirmButtonActive()}
                  action={() => this.viewModel.selectLocation()}
                >
                  Confirm
                </ButtonWidget>
              </div>
            </div>
          </div>
        </Scaffold>
      </div>
    );
  }
}

export default withSellerId(AddressSelectorViewController);
