import React from "react";
import { isResellerDomain } from "../../../CommonComponents/Utils";

export default class ProductDetailInformationWidget extends React.Component<{}> {
    render() {
        return(
            <div className="InformationDetail">
                <div className="col-12">
                    <div className="box_blue_product_detail">
                        <ul className="list-inline">
                            <li className="list-inline"><p className="BoxBlueTitle">Free Delivery</p></li>
                            <li className="list-inline"><p className="BoxBlueSubTitle">Free Shipping minimum order Rp. 300,000</p></li>
                        </ul>
                    </div>
                </div>
                {!isResellerDomain ?? <div className="col-12">
                    <div className="box_blue_product_detail">
                        <ul className="list-inline">
                            <li className="list-inline"><p className="BoxBlueTitle">Share to Earn</p></li>
                            <li className="list-inline"><p className="BoxBlueSubTitle">Get bonus points from every link you share</p></li>
                        </ul>
                    </div>
                </div>}
                <div className="col-12">
                    <div className="box_blue_product_detail">
                        <ul className="list-inline">
                            <li className="list-inline"><p className="BoxBlueTitle">Shipping From Korea</p></li>
                            <li className="list-inline"><p className="BoxBlueSubTitle">Estimated delivery from Korea - Jakarta about 7<br></br>working days</p></li>
                        </ul>
                    </div>
                </div>
                <div className="col-12">
                    <div className="box_blue_product_detail">
                        <ul className="list-inline">
                            <li className="list-inline"><p className="BoxBlueTitle">100% Original</p></li>
                            {!isResellerDomain() ? <li className="list-inline"><p className="BoxBlueSubTitle">Products are sent directly by authorized sellers or <br></br>brand companies. THEFEPI only processes<br></br> shipments to Indonesia</p></li>
                            : <li className="list-inline"><p className="BoxBlueSubTitle">Products are sent directly by authorized sellers or brand companies. We only processes shipments to Indonesia</p></li>}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}