import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_search from "../../Assets/Images/ic_search_dark.svg";

import "./ProductGroupMain.css";

import { useParams } from "react-router-dom";
import ViewController from "../../CommonComponents/Base/ViewController";
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ProductGroupMainViewModel from "./ProductGroupMainViewModel";
import StoreTopTabbarWidget from "../CommonComponents/Widgets/StoreTopTabbarWidget";
import StoreSectionFeedViewController from "../StoreSectionFeed/StoreSectionFeedViewController";
import ProductGroupMainSubCategoryButton from "./widgets/ProductGroupMainSubCategoryButton";
import { ReactNode } from "react";
import JastipScreen from "../Jastip/JastipScreen";
import StoreBrandHomeScreen from "../BrandHome/StoreBrandHomeScreen";
import { productTypesForBadgeUrls } from "../../CommonComponents/Utils";
import { mixpanelManager } from "../../Analytics/Mixpanel/Common";

export default function ProductGroupMainScreen() {
  const { groupId, categoryId } = useParams();

  return (
    <ProductGroupMainViewController
      groupId={groupId ?? ""}
      categoryId={categoryId ?? ""}
    />
  );
}

type ProductGroupMainViewControllerProps = {
  groupId: string;
  categoryId: string;
};

class ProductGroupMainViewController extends ViewController<
  ProductGroupMainViewControllerProps,
  ProductGroupMainViewModel
> {
  constructor(props: ProductGroupMainViewControllerProps) {
    super(
      props,
      new ProductGroupMainViewModel({
        groupId: props.groupId,
        categoryId: props.categoryId,
      })
    );
    this.viewModel.loadPreloadingProps();
    this.viewModel.loadContents();

    mixpanelManager.trackPageView("StoreProductGroupMain");
  }

  render() {
    const subCategoryButtonWidth = (this.state.width - 68) / 4;
    const categories = this.viewModel.model?.categories;
    const subCategories = this.viewModel.model?.subCategories;
    const feeds = this.viewModel.model?.feeds;
    let contentWidget: ReactNode;

    switch (this.viewModel.categoryId) {
      case "FashionJastip":
        contentWidget = <JastipScreen></JastipScreen>;
        break;
      case "FashionBrands":
        contentWidget = <StoreBrandHomeScreen></StoreBrandHomeScreen>;
        break;
      default:
        contentWidget = (feeds ?? []).map((feed, index) => {
          return (
            <StoreSectionFeedViewController
              model={feed}
              id={`StoreSection_${index}`}
            />
          );
        });
    }

    return (
      <Scaffold
        isShowLoading={this.viewModel.isLoading}
        showErrorModel={this.viewModel.errorMessage}
        selectedBottomBarItem="store"
        topBar={
          <>
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>
              {this.viewModel.model?.title ?? ""}
            </p>
            <div className="FlexFillSpacing"></div>
            <NavigationButton
              src={ic_search}
              onClick={() => {
                window.location.href = "/Store/Search";
              }}
            />
            <NavigationButton
              src={ic_cart}
              numberOnRightTop={this.viewModel.numberInCart}
              onClick={() => {
                window.location.href = "/Cart";
              }}
            />
          </>
        }
      >
        <div id="ProductGroupMainMainContentContainer">
          <div>
            {categories && (
              <StoreTopTabbarWidget
                selectedId={this.viewModel.categoryId}
                labels={categories.map((model) => {
                  return {
                    id: model.id,
                    label: model.label,
                    onTap: () =>
                      (window.location.href = `/Store/${this.viewModel.groupId}/${model.id}`),
                  };
                })}
              />
            )}
          </div>
          <div
            className="StoreProductsWithVideoContainer"
            style={{ marginTop: categories ? "32px" : "0px" }}
          >
            <div id="StoreProductScrollableContainer">
              {subCategories && subCategories.length > 0 && (
                <div id="ProductGroupMainSubCategoryContainer">
                  {subCategories.map((model) => {
                    return (
                      <ProductGroupMainSubCategoryButton
                        key={`ProductGroupMainSubCategoryButton_${model.id}`}
                        buttonWidth={subCategoryButtonWidth}
                        imageUrl={model.imageUrl ?? ""}
                        groupId={this.viewModel.groupId}
                        categoryId={this.viewModel.categoryId}
                        subCategoryId={model.id}
                        title={model.label}
                      />
                    );
                  })}
                </div>
              )}
              {contentWidget}
            </div>
          </div>
        </div>
      </Scaffold>
    );
  }
}
