import "./ContentEventDetail.css";
import React from "react";
import { useParams } from "react-router-dom";
import ViewController from "../../CommonComponents/Base/ViewController";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ContentEventDetailViewModel from "./ContentEventDetailViewModel";
import mixpanel from "mixpanel-browser";
import { isResellerDomain } from "../../CommonComponents/Utils";

export default function StoreMainHomeScreen() {
  const { contentId } = useParams();

  return <ContentEventViewController contentId={contentId} />;
}

type ContentEventViewControllerProps = {
  contentId?: string;
};

class ContentEventViewController extends ViewController<
  ContentEventViewControllerProps,
  ContentEventDetailViewModel
> {
  constructor(props: ContentEventViewControllerProps) {
    super(props, new ContentEventDetailViewModel(props.contentId ?? ""));

    this.viewModel.loadPreloadingProps();

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "Event",
    });
  }

  render() {
    const model = this.viewModel.model;
    const contentDetail = this.viewModel.contentDetail;
    const renderHTML = (rawHTML: string) =>
      React.createElement("div", {
        dangerouslySetInnerHTML: { __html: rawHTML },
      });

    return (
      <Scaffold
        isShowLoading={this.viewModel.isLoading}
        creatorId={this.viewModel.creatorId}
        isCreator={this.viewModel.isCreator}
        showErrorModel={this.viewModel.errorMessage}
      >
        <div
          id="ContentDetailContainer"
          className="container"
          style={{ width: "100%" }}
        >
          <div className="row">
            <div className="col-12">
              <a href="/Store">{"<"} Back</a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <br></br>
              <p>{model?.createDate + " [" + model?.categoryName + "]"}</p>
              <h5>{model?.title}</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12">{renderHTML(contentDetail)}</div>
          </div>
        </div>
      </Scaffold>
    );
  }
}
