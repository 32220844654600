import "./App.css";
import { FepiRouter } from "./Router/FepiRouter";
import "bootstrap/dist/css/bootstrap.css";
import { isResellerDomain } from "./CommonComponents/Utils";
import { mixpanelManager } from "./Analytics/Mixpanel/Common";
import { HelmetProvider } from "react-helmet-async";

global.ImgUrl = "https://images.thefepi.com/file/";
global.ApiServerLive = "https://staging.shopable.co.id/mobile/";

function App() {
  mixpanelManager.init();

  return (
    <HelmetProvider>
      <FepiRouter />
    </HelmetProvider>
  );
}

export default App;
