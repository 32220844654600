import React, { ReactNode, useEffect, useState } from "react";
import "./DraggableBottomSheet.css";
import "./SmallBannerAIPreferenceWidget.css";
import DraggableBottomSheet from "../../../../CommonComponents/UI/DraggableBottomSheetAIPreference/DraggableBottomSheet";
import ic_arrow_left from "../../../../Assets/Images/ic_arrow_left.svg";
import SizedDiv from "../../../../CommonComponents/UI/SizedDiv";
import { LoadingWidget } from "../../../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import { requestAPI } from "../../../../CommonComponents/ApiRequest/APIRequest";
import { Endpoints } from "../../../../CommonComponents/ApiRequest/Endpoints";
import StoreAIPreferenceModel from "./AIPreferenceModel";
import BaseResponse from "../../../../CommonComponents/ApiRequest/BaseResponse";
import { getDeviceModel } from "../../../../CommonComponents/Utils";
import ic_checkbox_sel from "../../../../Assets/Images/ic_checkbox_sel.svg";
import ic_checkbox_sel_black from "../../../../Assets/Images/ic_checkbox_sel_black.svg";
import loading_orbit from "../../../../Assets/Images/loading_orbit.svg";
import Scaffold, {
  ShowErrorModel,
} from "../../../../CommonComponents/UI/Scaffold/Scaffold";

type DraggableBottomSheetProps = {
  onRetryButtonTapped?: () => void;
  isShown?: boolean;
  onClosed?: () => void;
  children?: ReactNode;
  animateOut?: boolean;
  imageBanner: {
    title: string;
    imageUrl: string;
    deeplink: string;
  }[];
};

const SmallBannerBottomSheet = (props: DraggableBottomSheetProps) => {
  const [showSheet, setShowSheet] = useState(false);
  const [animateOutSheet, setAnimateOutSheet] = useState(false);
  const [preferenceData, setPreferenceData] =
    useState<StoreAIPreferenceModel | null>(null);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedStyle, setSelectedStyle] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page2, setPage2] = useState(false);
  const [maxSelected, setMaxSelected] = useState(0);
  const [minSelected, setMinSelected] = useState(0);
  const [errorMessage, setErrorMessage] = useState<ShowErrorModel | null>(null); // Initialize error message state

useEffect(() => {
  if (showSheet) {
    // Make the API call when showSheet becomes true
    requestAPI(Endpoints.AIpreferece, {
      parameters: {
        // Add your API parameters here
      },
      onSuccess: (response: BaseResponse<StoreAIPreferenceModel>) => {
        // Handle a successful API response
        const data = response.data;
        setPreferenceData(data);
        setMaxSelected(data.style.maxSelect);
        setMinSelected(data.style.minSelect);
      },
      onFailure: (error: any) => {
        // Handle API call failure by setting the error message
        setErrorMessage({
          message: error,
          onRetryButtonTapped: () => {}, // Retry action (you can customize this)
        });
      },
    });
  }
}, [showSheet]);
  
  const SubmitAIPreference = () => {
    setIsLoading(true);
    requestAPI(Endpoints.submitAIpreference, {
      parameters: {
        fpAuth: localStorage.fpAuth,
        deviceId: localStorage.uuid,
        deviceModel: getDeviceModel(2),
        selectedGenderId: selectedGender === "Male" ? "1" : "0",
        selectedStyleIds: selectedStyle,
      },
      onSuccess: (response: BaseResponse<{}>) => {
        if (response.result) {
          window.location.href = "/Store";
        }

        setTimeout(() => {
          setIsLoading(false); // Set loading state to false after a 1-second delay
        }, 1000);
      },
      onFailure: () => {
        // this.emit(() => {});
      },
    });
  };

  const openSheet = () => {
    setShowSheet(true);
    const element = document.getElementById(
      "ContentExplorerBottomNavigationContainer"
    );

    if (element) {
      element.style.zIndex = "0";
    }
  };



  useEffect(() => {
    if (!showSheet) {
      props.onClosed?.();
      const element = document.getElementById(
        "ContentExplorerBottomNavigationContainer"
      );

      if (element) {
        element.style.zIndex = "10";
      }
    }
  }, [showSheet, props.animateOut]);

  const handleCloseBottomSheet = () => {
    setAnimateOutSheet(true);
    setTimeout(() => {
      setShowSheet(false);
      setAnimateOutSheet(false);
    }, 500);
  };

  const toggleImageSelection = (id: string) => {
    // Check if the index is already in the selectedStyle array
    const indexInArray = selectedStyle.includes(id);

    if (indexInArray) {
      // If the style is already selected, remove it from the array
      setSelectedStyle((prevSelectedStyles) => {
        return prevSelectedStyles.filter((selectedId) => selectedId !== id);
      });
    } else {
      // If the style is not selected and the count is less than 5, add it to the array
      if (selectedStyle.length <= maxSelected) {
        setSelectedStyle((prevSelectedStyles) => [...prevSelectedStyles, id]);
      }
    }
  };

  const handleClickGender = (label: string) => {
    setSelectedGender(label);
    setTimeout(() => {
      setPage2(true); // Set loading state to false after a 1-second delay
    }, 1000);
  };

  function handlClickNext() {
    if (selectedStyle.length >= minSelected) {
      SubmitAIPreference();
    }
  }

  const handleBack = () => {
    if (page2 === true) {
      setPage2(false);
    }

    if (page2 === false) {
      setSelectedGender("");
      handleCloseBottomSheet();
    }
  };

  const handleClickCancel = () => {
    handleBack();
  };

  const arrayLength = preferenceData?.style.items.length ?? 0;
  const splitSize = Math.ceil(arrayLength / 2);
  const listA = preferenceData?.style.items.slice(0, splitSize);
  const listB = preferenceData?.style.items.slice(splitSize, arrayLength);


  const renderContent = () => {
    if (page2 === false) {
      return (
        <>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "12px 16px"
              }}>
              <img
                style={{
                 width: "100%",
                  borderRadius: "12px",
                }}
                src={preferenceData?.explanation.imageUrl}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: "16px",
                paddingLeft: "16px",
                gap: "8px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                }}>
                Fill out this AI-driven short survey to tailor your shopping
                experience!
              </div>

              <div
                style={{
                  color: "#626262",
                }}>
                You can edit later on Setting
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: "16px",
                paddingLeft: "16px",
                gap: "16px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}>
              <div>Select Gender</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                }}>
                {preferenceData?.genders?.map((genders) => (
                  <div
               
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      // alignItems: "center",
                      textAlign: "center",
                      height: "69px",
                      border:
                        selectedGender === genders.label
                          ? "1px solid  #FFF"
                          : "1px solid #626262",
                      borderRadius: "12px",
                      width: "136px",
                    }}
                    onClick={() => handleClickGender(genders.label)}>
                    <div
                      style={{
                        width: "45.33px",
                      }}></div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "45.33px",
                      }}>
                      <div>{genders.label}</div>
                    </div>

                    <div
                      style={{
                        width: "45.33px",
                        paddingTop: "6px",
                      }}>
                      <img
                        style={{
                          display:
                            selectedGender !== genders.label ? "none" : "",
                        }}
                        src={ic_checkbox_sel}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    } else if (selectedGender !== "") {
      return (
        <>
          <div
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="C">
            <div>Pick min. {minSelected} of your favourite styles</div>
            <div>
              {selectedStyle.length + " "}
              selected
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "16px",
              width: "100%",
              gap: "10px",
              justifyContent: "center",
            }}>
            <div
              className="image-grid"
              style={{ display: "flex", flexDirection: "column" }}>
              {listA?.map((data, index) => (
                <>
                  <div className="image-container">
                    <div
                    
                      className={`image-item ${
                        selectedStyle.includes(data.id) ? "selected" : ""
                      }`}
                      onClick={() => toggleImageSelection(data.id)}
                      style={{
                        background: `url(${data.imageUrl})`,
                        backgroundSize: "cover",
                        border: selectedStyle.includes(data.id)
                          ? "2px solid #FFF"
                          : "none",
                        borderRadius: "12px",
                        height: index % 2 === 0 ? "200px" : "160px",
                        width: "160px",
                        display: "flex",
                        justifyContent: "end",
                        padding: "12px",
                      }}>
                      {selectedStyle.includes(data.id) && (
                        <img
                          src={ic_checkbox_sel_black}
                          style={{ height: "20px", width: "20px" }}
                        />
                      )}
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div
              className="image-grid"
              style={{ display: "flex", flexDirection: "column" }}>
              {listB?.map((data, index) => (
                <>
                  <div  className="image-container">
                    <div
                    
                      className={`image-item ${
                        selectedStyle.includes(data.id) ? "selected" : ""
                      }`}
                      onClick={() => toggleImageSelection(data.id)}
                      style={{
                        backgroundImage: `url(${data.imageUrl})`,
                        backgroundSize: "cover",
                        border: selectedStyle.includes(data.id)
                          ? "2px solid #FFF"
                          : "none",
                        borderRadius: "12px",
                        height: index % 2 === 0 ? "160px" : "200px",
                        width: "160px",
                        display: "flex",
                        justifyContent: "end",
                        padding: "12px",
                      }}>
                      {selectedStyle.includes(data.id) && (
                        <img
                          src={ic_checkbox_sel_black}
                          style={{ height: "20px", width: "20px" }}
                        />
                      )}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <SizedDiv height={100} />
        </>
      );
    }
  };

  return (
    <>
      {/* Add a click event to open the DraggableBottomSheet */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}>
        <img
          style={{
            width: "100%",
            borderRadius: "8px",
          }}
          onClick={openSheet}
          src={props.imageBanner[0].imageUrl}
        />
      </div>

      {showSheet && (
        <>
          <DraggableBottomSheet
            isShown={showSheet}
            onClosed={() => handleCloseBottomSheet()}
            animateOut={animateOutSheet}>
            {isLoading && (
              <div
                style={{
                  backgroundColor: "black",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  zIndex: "100",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: "85%",
                }}>
                <img src={loading_orbit} alt="" style={{ width: "100%" }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                    color: "white",
                    width: "295px",
                    textAlign: "center",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #FFF",
                      borderRadius: "20px",
                      padding: "8px 24px ",
                    }}>
                    <LoadingWidget theme="dark" />
                  </div>
                  <div>
                    Sit tight while our AI personalizes your shopping
                    experience...
                  </div>
                </div>
              </div>
            )}

            {!errorMessage ? (
              <div
                className="DraggableBottomSheetContents"
                style={{
                  color: "white",
                }}>
                <div
                  className="Container"
                  style={{
                    position: "fixed",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    backgroundColor: "black",
                    height: "80px",
                    borderRadius: "12px 12px 0px 0px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    gap: "10px",
                  }}>
                  <div
                    className="A"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingRight: "24px",
                      paddingLeft: "24px",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                      }}>
                      <img src={ic_arrow_left} onClick={() => handleBack()} />
                      <div style={{ fontSize: "18px", fontWeight: "700" }}>
                        AI Preference Tailor
                      </div>
                    </div>
                    <div
                      onClick={() => handleClickCancel()}
                      style={{ fontSize: "14px", fontWeight: "500" }}>
                      Cancel
                    </div>
                  </div>
                  <SizedDiv height={20} />

                  <div
                    className="B"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      backgroundColor: "black",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingRight: "24px",
                        paddingLeft: "24px",
                        gap: "4px",
                        paddingBottom: "16px",
                      }}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                        }}>
                        {selectedGender !== "" ? "2" : "1"} of 2
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        }}>
                        <div
                          style={{
                            height: "2px",
                            width: "100%",
                            backgroundColor: "white",
                          }}></div>
                        <div
                          style={{
                            height: "2px",
                            width: "100%",
                            backgroundColor:
                              page2 === true ? "white" : "#626262",
                          }}></div>
                      </div>
                    </div>
                    {/* {renderContent()} */}
                  </div>
                </div>
                <SizedDiv height={100} />

                 { renderContent()}
        

                {page2 &&  (
                  <div
                    style={{
                      position: "fixed",
                      bottom: "0",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      backgroundColor: "black",
                      padding: "12px",
                    }}
                    onClick={() => handlClickNext()}>
                    <div
                      style={{
                        width: "100%",
                        height: "50px",
                        backgroundColor:
                          selectedStyle.length >= minSelected
                            ? "#00C2C2"
                            : "#383838",
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color:
                          selectedStyle.length >= minSelected
                            ? "white"
                            : "#626262",
                      }}>
                      Next
                    </div>
                  </div>
                ) }
              </div>
            ) : (
              <Scaffold
                selectedBottomBarItem="showcase"
                backgroundColor="black"
                showErrorModel={errorMessage}></Scaffold>
            )}
          </DraggableBottomSheet>
        </>
      )}
    </>
  );
};

export default SmallBannerBottomSheet;
