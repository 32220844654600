import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent";
import StoreBrandHomeViewModel from "./StoreBrandHomeViewModel";

import ic_magnifier from "../../Assets/Images/ic_magnifier.svg";
import ic_x from "../../Assets/Images/ic_x_circle_grey_filled.svg";

import "./StoreBrandHomeScreen.css";
import { ImageWidget } from "../../CommonComponents/UI/ImageWidget/ImageWidget";
import { mixpanelManager } from "../../Analytics/Mixpanel/Common";

export default class StoreBrandHomeScreen extends BaseUIComponent<
  {},
  StoreBrandHomeViewModel
> {
  constructor(props: {}) {
    super(props, new StoreBrandHomeViewModel());
    this.viewModel().loadBrands();

    mixpanelManager.trackPageView("SearchBrands");
  }

  render() {
    const categorizedBrands = this.viewModel().filteredCategorizedBrands;

    return (
      <div id="StoreBrandHomeMainContainer">
        <div id="SearchBarContainer">
          <div id="SearchBarBorder">
            <img src={ic_magnifier} alt="" />
            <div>
              <input
                type="text"
                value={this.viewModel().query}
                onChange={(value) =>
                  this.viewModel().changeQuery(value.target.value)
                }
              />
              {<p>{this.viewModel().query.length === 0 && "Search Brand"}</p>}
            </div>
            {this.viewModel().query.length > 0 && (
              <button onClick={() => this.viewModel().changeQuery("")}>
                <img src={ic_x} alt="" />
              </button>
            )}
          </div>
        </div>
        <div id="StoreBrandHomeResultListContainer">
          {categorizedBrands && (
            <>
              {Object.keys(categorizedBrands)
                .sort()
                .map((value, index) => (
                  <>
                    <div
                      key={`TitleContainer_${value}`}
                      style={{ borderBottom: "1px solid var(--whiteGrey)" }}
                    >
                      {index !== 0 && <div style={{ height: "12px" }} />}
                    </div>
                    <div
                      id="StoreBrandHomeAlphabetIndexHeader"
                      key={`TitleContainerSpacing_${value}`}
                    >
                      {value}
                    </div>
                    {categorizedBrands[value].map((model) => {
                      const query = this.viewModel().query.toLowerCase();
                      const queryInWordStartIndex =
                        query.length > 0
                          ? model.name.toLowerCase().indexOf(query)
                          : -1;

                      const titleLabel =
                        queryInWordStartIndex === model.name.length - 1 ? (
                          <>{model.name}</>
                        ) : (
                          <>
                            {model.name.substring(0, queryInWordStartIndex)}
                            <strong style={{ color: "var(--purple)" }}>
                              {model.name.substring(
                                queryInWordStartIndex,
                                queryInWordStartIndex + query.length
                              )}
                            </strong>
                            {model.name.substring(
                              queryInWordStartIndex + query.length
                            )}
                          </>
                        );

                      return (
                        <button
                          key={`cell_${model.id}`}
                          onClick={() => {
                            mixpanelManager.brandClick(
                              model.id,
                              model.name,
                              "SearchBrands"
                            );
                            window.location.href = `/Brand/${model.id}`;
                          }}
                        >
                          <div
                            style={{
                              height: "48px",
                              width: "48px",
                              borderRadius: "24px",
                              overflow: "hidden",
                            }}
                          >
                            <ImageWidget imageUrl={model.imageUrl} />
                          </div>
                          <p>{titleLabel}</p>
                        </button>
                      );
                    })}
                  </>
                ))}
            </>
          )}
        </div>
      </div>
    );
  }
}
