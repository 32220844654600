import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import PaymentViewModel from "./PaymentViewModel";
import { Navigate } from "react-router-dom";

import "./Payment.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_cart_black from "../../Assets/Images/ic_cart_black.svg";
import ic_back from "../../Assets/Images/ic_back.svg";
import ic_chevron_left from "../../Assets/Images/ic_chevron_left.svg";
import ic_arrow_right from "../../Assets/Images/ic_arrow_right.svg";
import ic_discount from "../../Assets/Images/ic_discount.svg";
import ViewController from "../../CommonComponents/Base/ViewController";
import {
  WithCreatorLinkProps,
  getResellerPhoneNumber,
  isResellerDomain,
  withSellerId,
} from "../../CommonComponents/Utils";
import BackButton from "../../CommonComponents/UI/BackButton/BackButton";
import mixpanel from "mixpanel-browser";

class CheckoutScreen extends ViewController<
  WithCreatorLinkProps,
  PaymentViewModel
> {
  constructor(props: WithCreatorLinkProps) {
    super(props, new PaymentViewModel({ creatorLink: props.creatorLink }));

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: isResellerDomain() ? "SelectPayment" : "Payment",
    });
  }

  handleChangePayment = () => {};

  handleSubmitBCA = () => {
    this.viewModel.handleSubmitBCA();
  };

  handleSubmitMidtrans = () => {
    this.viewModel.handleSubmitMidtrans();
  };

  handleSubmitPayment = (paymentType: string) => {
    this.viewModel.handleSubmitPayment(paymentType);
  };

  render() {
    if (localStorage.getItem("selectedBagItem") === null) {
      return <Navigate to="/" />;
    }

    return (
      <Scaffold
        isShowLoading={false}
        showSnackbar={this.viewModel.snackbarMessage}
        showErrorModel={this.viewModel.errorModel}
        topBar={
          <>
            <BackButton>
              <img alt="" id="pageBackButton" src={ic_chevron_left}></img>
            </BackButton>
            <p id="pageTitle">Select Payment</p>
          </>
        }
      >
        <div id="Payment" className="container">
          <br></br>

          {this.viewModel.paymentMethods?.map((pm, i) => {
            return (
              <div className="box_putih" key={"pm_" + i}>
                <div className="row">
                  <div className="col" style={{ padding: "0px 20px" }}>
                    <img
                      alt={pm.type}
                      style={{ borderRadius: "3px" }}
                      width="40"
                      src={pm.imageUrl}
                    ></img>
                    <span className="paymentTitle">&nbsp;&nbsp;{pm.title}</span>
                    {pm.promos?.map((promo, i) => (
                      <div className="beneficial">
                        <img alt="discount" src={ic_discount} />
                        &nbsp;
                        <p>{promo}</p>
                      </div>
                    ))}
                    {pm.description && <div style={{ height: "12px" }}></div>}
                    {pm.description?.split("\n").map((line, i) => (
                      <>
                        {line.length > 0 ? (
                          <p
                            style={{
                              lineHeight: "18px",
                              fontSize: "12px",
                              marginBottom: "8px",
                            }}
                            key={i}
                          >
                            {line}
                          </p>
                        ) : pm.description === "" ? (
                          <div style={{ height: "4px" }} />
                        ) : (
                          <br />
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <button
                      onClick={() => this.handleSubmitPayment(pm.type)}
                      className="btn_payment"
                    >
                      {pm.title}&nbsp;&nbsp;&nbsp;
                      <img src={ic_arrow_right} />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}

          <div
            id="SpaceNavigationBackground"
            className="row"
            style={{ opacity: `${this.viewModel.navBarTransparency}` }}
          ></div>
          <div className="row floatingButton">
            {isResellerDomain() ? (
              <button
                id="SpaceHomeButton"
                onClick={() => window.history.back()}
              >
                <img src={ic_back} alt="" />
              </button>
            ) : (
              <button
                id="SpaceHomeButton"
                onClick={() => (window.location.href = "/")}
              >
                <img src={ic_thefepi_f} alt="" />
              </button>
            )}
            <button
              id="SpaceCartButton"
              onClick={() => {
                window.location.href = `${
                  isResellerDomain() ? `/shop/${this.props.creatorLink}` : ""
                }/Cart`;
              }}
            >
              <img src={isResellerDomain() ? ic_cart_black : ic_cart} alt="" />
              {(this.viewModel.numberInCart ?? 0) > 0 && (
                <div>{this.viewModel.numberInCart}</div>
              )}
            </button>
          </div>

          {isResellerDomain() ? (
            <ChannelChatPopup
              resellerNumber={getResellerPhoneNumber(this.props.creatorLink)}
              message={`Halo, ${this.props.creatorLink}`}
            />
          ) : (
            <ChannelChatPopup />
          )}
        </div>
      </Scaffold>
    );
  }
}

export default withSellerId(CheckoutScreen);
