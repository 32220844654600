import React from "react";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { ProductItemModel } from "../Models/ContentDisplayModels";

import "./ContentDisplay.css";

import ic_product_placeholder from "../../../Assets/Images/ic_product_placeholder.svg";

type ContentDisplayProductCellProps = {
    productModel: ProductItemModel,
    sellerId?: string,
    contentId?: string
}

interface State {
    showAgeRestrictedModal: boolean
}

export class ContentDisplayProductCell extends React.Component<ContentDisplayProductCellProps, State> {
    constructor(props: ContentDisplayProductCellProps) {
        super(props);

        this.state = {
            showAgeRestrictedModal: false
        };
    }



    render() {
        const product = this.props.productModel;
        const sellerId = this.props.sellerId;
        return (
          <div id="ContentDisplayProductCell">
            <div
              id="ContentDisplayProductCellRow"
              onClick={() =>
                product.ageRestricted === false ||
                product.ageRestricted === undefined
                  ? (window.location.href = `/Product/${product.id}?creator=${sellerId}&contentId=${this.props.contentId}`)
                  : this.showAgeRestrictedModal(product.id, sellerId)
              }>
              <div id="ContentDisplayProductCellImage">
                <ImageWidget
                  imageUrl={product.imageUrl ?? ""}
                  placeholder={ic_product_placeholder}
                />
              </div>
              <div id="ContentDisplayProductCellLabelColumn">
                {product.title !== null && (
                  <p className="EqualFlexChild ContentDisplayProductCellBrand">
                    {product.title}
                  </p>
                )}
                {product.description !== null && (
                  <p className="EqualFlexChild ContentDisplayProductCellNameAndDescription">
                    {product.description}
                  </p>
                )}
                {product.priceLabel !== null && (
                  <p className="EqualFlexChild ContentDisplayProductCellNameAndDescription">
                    {product.priceLabel}
                  </p>
                )}
              </div>
            </div>
          </div>
        );
    }

    showAgeRestrictedModal(productId: string, sellerId?: string){
        const scaffold = document.querySelectorAll("#Scaffold")[0];
        scaffold.appendChild(document.createElement('div')).className = "modal-backdrop show";
        const append = scaffold.appendChild(document.createElement('div'));
        append.className = "modal fade show";
        append.tabIndex = 1;
        const htmldata = `
        <div class="modal-dialog" role="document" id="AgeRestrictedModal"> 
            <div class="modal-content"> 
                <div class="modal-body"> 
                    <p><b>Age Confirmation</b></p>
                    <span>You must be over 21 to access this items</span>
                <div class="row" style="margin-top:36px;"> 
                    <div class="col text-center">
                        <button id="GoButton" class="btn btn-secondary">I’m over 21</button>
                    </div>
                    <div class="col text-center">
                        <button id="CancelButton" data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        `;
        append.innerHTML = htmldata;
        const GoButton = document.getElementById("GoButton");
        const cancelButton = document.getElementById("CancelButton");

        if (GoButton) {
            GoButton.onclick = (_) => {
                this.closeAgeRestrictedModal();
                return window.location.href = `/Product/${productId}${sellerId !== undefined ? `?creator=${sellerId}` : ""}`;
            }
        }
        if (cancelButton) {
            cancelButton.onclick = (_) => {
                return this.closeAgeRestrictedModal();
            }
        }
    }

    closeAgeRestrictedModal(){
        const modal = document.querySelectorAll(".modal")[0];
        modal.remove();
        const modalBackdrop = document.querySelectorAll(".modal-backdrop")[0];
        modalBackdrop.remove();
    }
}