import React from "react";
import 'bootstrap/dist/js/bootstrap.min.js';
import ProductDetailResponse from "../Models/ProductDetailResponse";
import ic_photo_profile_placeholder from "../../../Assets/Images/ic_photo_profile_placeholder.svg";

type ProductDetailCreatorInformationWidgetProps = {
    productDetail: ProductDetailResponse | null;
};

export default class ProductDetailCreatorInformationWidget extends React.Component<ProductDetailCreatorInformationWidgetProps> {
   render() {
        const sellerProfile = this.props.productDetail?.sellerProfile;
        return(
            <div className="CreatorInformation">
                {
                    sellerProfile?.name !== undefined &&
                    <ul className="list-inline">
                        {// @ts-ignore
                        <li className="list-inline-item"><img width="35" className="creatorPhoto" src={sellerProfile?.imageUrl} placeholder={ic_photo_profile_placeholder} alt=""></img></li>
                        }
                        <li className="list-inline-item"><span>{sellerProfile?.name}</span></li>
                        <li className="list-inline-item">&nbsp;&nbsp;<img width="17" src={ImgUrl+'shopable/icSellershopS@2x.png'} alt=""></img></li>
                    </ul>
                }
            </div>
        );
    }
}