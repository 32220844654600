import FacebookLogin from "react-facebook-login";
import { useGoogleLogin } from "react-google-login";
import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import mixpanel from "mixpanel-browser";
import { isResellerDomain } from "../../CommonComponents/Utils";

const googleClientId =
  "592286354232-dp3q0g6l7olp0esmbvthkgm7j497a2k1.apps.googleusercontent.com";

export default function Login() {
  //MIXPANEL TRACKING: semo_page_view / screen_view
  mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
    page_name: "MainLogin",
  });

  const onGoogleSignInSuccess = (res: any) => {
    requestAPI(Endpoints.loginGoogle, {
      parameters: {
        googleLogin: res.profileObj.googleId,
        email: res.profileObj.email,
        full_name: res.profileObj.name,
        last_name: res.profileObj.familyName,
        foto: res.profileObj.imageUrl,
      },
      onSuccess: (response: { data?: { fpAuth: string } }) => {
        localStorage.setItem("fpAuth", response.data?.fpAuth ?? "");
        localStorage.removeItem("cn");
        localStorage.removeItem("uuid");
        localStorage.removeItem("mixpanelSuperProperties");
        mixpanel.reset();
        window.location.href = "/";
      },
      onFailure: (error: string) => {
        window.location.href = "/Login/";
      },
    });
  };

  const responseFacebook = (responses: any) => {
    if (responses.email) {
      requestAPI(Endpoints.loginFacebook, {
        parameters: {
          facebookLogin: responses.id,
          emailOrPhone: responses.email,
          full_name: responses.name,
          last_name: responses.name,
          foto: responses.picture.data.url,
        },
        onSuccess: (response: { data?: { fpAuth: string } }) => {
          localStorage.setItem("fpAuth", response.data?.fpAuth ?? "");
          localStorage.removeItem("cn");
          localStorage.removeItem("uuid");
          localStorage.removeItem("mixpanelSuperProperties");
          mixpanel.reset();
          window.location.href = "/";
        },
        onFailure: (error: string) => {
          window.location.href = "/Login/";
        },
      });
    }
  };

  const { signIn } = useGoogleLogin({
    onSuccess: onGoogleSignInSuccess,
    clientId: googleClientId,
    isSignedIn: false,
  });

  return (
    <Scaffold>
      <div id="Login" className="container">
        <div className="row">
          <div className="col no-padding text-center">
            <br />
            <br />
            <img
              width="250"
              className="img-fluid"
              alt=""
              src="https://images.thefepi.com/file/logo_main.png"
            ></img>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "20px" }}>
          <div className="col text-center">
            <div
              onClick={signIn}
              style={{
                border: "solid #cecece 1px",
                padding: "20px",
                borderRadius: "16px",
              }}
            >
              <div className="row">
                <div className="col-2 text-center">
                  <img
                    className="img-fluid"
                    alt=""
                    src={global.ImgUrl + "shopable/img-google.png"}
                  ></img>
                </div>
                <div className="col-10" style={{ marginLeft: "-15px" }}>
                  <span style={{ fontWeight: "bold" }}>Log in with Google</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "10px" }}>
          <div className="col text-center">
            <FacebookLogin
              appId="1408696446192941"
              autoLoad={false}
              fields="email,name,picture"
              callback={responseFacebook}
              cssClass="btnFacebook"
              icon="fa-facebook"
            />
          </div>
        </div>
        <div className="row" style={{ paddingTop: "30px" }}>
          <div
            className="col text-center"
            style={{ borderTop: "solid #cecece 1px" }}
          >
            <p
              style={{
                position: "relative",
                top: "-10px",
                zIndex: "20",
                backgroundColor: "white",
                width: "40px",
                left: "45%",
              }}
            >
              OR
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <a href="/LoginSso/" style={{ textDecoration: "none" }}>
              <div
                style={{
                  border: "solid #cecece 1px",
                  padding: "20px",
                  borderRadius: "16px",
                  backgroundColor: "black",
                }}
              >
                <div className="row">
                  <div className="col-12">
                    <span style={{ color: "white", fontWeight: "bold" }}>
                      Log in with Email / Phone
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "40px" }}>
          <div className="col text-center">
            <p style={{ fontSize: "12px" }}>
              New Here?{" "}
              <a
                href="/Register/"
                style={{
                  fontSize: "12px",
                  textDecoration: "underline",
                  fontFamily: "SF Pro Bold",
                }}
              >
                create account
              </a>
            </p>
            <p style={{ marginTop: "20px" }}>
              <a
                href="/Store/"
                style={{
                  fontSize: "14px",
                  color: "#383838",
                  textDecoration: "underline",
                  fontFamily: "SF Pro Bold",
                }}
              >
                SKIP FOR NOW
              </a>
            </p>
          </div>
        </div>
      </div>
    </Scaffold>
  );
}
