import { useEffect, useState } from "react";
import ic_arrow_down from "../../Assets/Images/ic_arrow_down.svg";
import ic_arrow_up from "../../Assets/Images/ic_arrow_up.svg";
import ic_arrow_left_black from "../../Assets/Images/ic_arrow_left_black.svg";


interface AllCategoryBottomSheetProps {
  isVisible: boolean;
  dataAllCategory: any;
  onClose: () => void;
  onClick: () => void;
}

type subCategoryDetailModel = {
  id: string;
  label: string;
};

type subCategory = {
  id: string;
  label: string;
  subCategoryDetail: subCategoryDetailModel[];
};
type groupsModel = {
  id: string;
  label: string;
  subCategory: subCategory[];
};

export type AllCategoryResponse = {
  groups: groupsModel[];
};


export default function AllCategoryBottomSheetController(props: AllCategoryBottomSheetProps) {
  const [isDataAllCategory, setIsDataAllCategory] = useState<AllCategoryResponse | null>(null);
  const [isSelectedGroup, setIsSelectedGroupId] = useState("Fashion");
  const [isSelectedCategory, setIsSelectedSubcategory] = useState<
    string | null
    >(null);
  
  

 
  useEffect(() => {
    setIsDataAllCategory(props.dataAllCategory);
  }, [props.dataAllCategory]);


  const onSelectCategory = (data: string) => {
    setIsSelectedGroupId(data);
  }

  const onSelectSubcategory = (data: string) => {
    setIsSelectedSubcategory((prevSelected) =>
      prevSelected === data ? "" : data
    );
  };


  return (
    <>
      <div id={props.isVisible ? "PickerSheetActive" : "PickerSheet"}>
        <div id="containerSheet">
          <div
            id="HeadSheet"
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "20px",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: "16px",
              justifyContent: "space-between",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}>
              <div onClick={props.onClose}>
                <img src={ic_arrow_left_black} alt="" />
              </div>
              <div>All category</div>
            </div>
            <div onClick={props.onClose}>close</div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              maxHeight: "calc(100vh)",
              position: "fixed",
              top: 0,
              width: "100%",
              paddingTop: "60px",
              // zIndex: 1000,
            }}>
            <div
              id="row1"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                padding: "12px 0px",
                alignItems: "flex-start",
                background: "#F8F8F8",
                zIndex: 101,
              }}>
              {isDataAllCategory &&
                isDataAllCategory.groups.map((group: any) => {
                  return (
                    <div
                      id="col1"
                      style={{
                        display: "flex",
                        padding: "14px 16px",
                        alignItems: "flex-start",
                        gap: "12px",
                        backgroundColor:
                          group.label === isSelectedGroup ? "white" : "",
                        width: "100%",
                      }}
                      onClick={() => onSelectCategory(group.label)}>
                      {group.label}
                    </div>
                  );
                })}
            </div>
            <div
              id="row2-container"
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                background: "white",
                width: "100%",
                paddingBottom: "50px",
              }}>
              {isDataAllCategory &&
                isDataAllCategory.groups
                  .filter((data: any) => data.label == isSelectedGroup)
                  .map((group: any) =>
                    group.subCategory.map((sub: any) => {
                      return (
                        <>
                          <div
                            id="col1"
                            style={{
                              display: "flex",
                              gap: "12px",
                              padding: "14px 16px",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              width: "100%",
                            }}
                            onClick={() => onSelectSubcategory(sub.id)}>
                            <div>{sub.label}</div>
                            <div>
                              <img
                                src={
                                  sub.id == isSelectedCategory
                                    ? ic_arrow_down
                                    : ic_arrow_up
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          {sub.subCategoryDetail &&
                            sub.subCategoryDetail.map((item: any) => {
                              return (
                                <div
                                  id="subCol1"
                                  style={{
                                    display:
                                      sub.id === isSelectedCategory
                                        ? "flex"
                                        : "none",
                                    gap: "12px",
                                    padding: "14px 16px",
                                    flexDirection: "row",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    window.location.href = `/Store/V3new/${isSelectedGroup}/${isSelectedCategory}/${item.id}`;
                                  }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "12px",
                                      paddingLeft: "16px",
                                      flexDirection: "row",
                                      width: "100%",
                                    }}>
                                    {item.label}
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      );
                    })
                  )}
            </div>
          </div>
        </div>
      </div>
      {props.isVisible && (
        <div
          id="SheetUnderlay"
          style={{
            position: "fixed",
            inset: "0",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: "50",
          }}
          onClick={props.onClose}
        />
      )}
    </>
  );
}