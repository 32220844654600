import mixpanel, { Callback } from "mixpanel-browser";
import {
  getDeviceId,
  requestAPI,
} from "../../CommonComponents/ApiRequest/APIRequest";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import {
  getDeviceBrandAndModel,
  isResellerDomain,
} from "../../CommonComponents/Utils";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import MyPageResponse from "../../UserPlatform/MyPage/Models/MyPageResponse";

class MixpanelManager {
  private STAGING_MIXPANEL_TOKEN = "fef25edf829c8fbf61ad559d9b2e580c";
  private PROD_MIXPANEL_TOKEN = "afcf6bd26ab723a4df7091e40e5ea16b";
  private debug = process.env.REACT_APP_ENV !== "production";
  private mixpanelToken = this.debug
    ? this.STAGING_MIXPANEL_TOKEN
    : this.PROD_MIXPANEL_TOKEN;

  init() {
    mixpanel.init(this.mixpanelToken, {
      debug: false,
      track_pageview: false,
      persistence: "localStorage",
    });

    // CHECK IF NOT ALREADY INITIATED
    if (mixpanel.get_property("distinct_id") !== this.getCn()) {
      // Debug
      if (this.debug) console.log("mixpanel initiating");

      // Identify
      mixpanel.identify(this.getCn());

      // Ensure and register super properties
      this.ensureSuperProperties();

      // Ensure people if logged in
      if (isResellerDomain()) {
        mixpanel.people.set({
          $name: "semo-" + this.getCn(),
        });
      } else {
        if (localStorage.getItem("fpAuth")) this.ensurePeople();
      }
    }
  }

  // ======
  // EVENTS
  // ======

  track(eventName: string, properties: any) {
    if (this.debug) console.log("mixpanel firing event", eventName, properties);
    mixpanel.track(eventName, properties);
  }

  trackPageView(pageName: string) {
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: pageName,
    });
  }

  tabClick(title: string, group_name: string, callback?: Callback) {
    mixpanel.track(
      "tab_click",
      {
        title: title.toUpperCase(),
        group_name: group_name.toUpperCase().replace(" ", "_"),
      },
      callback
    );
  }

  searchDefaultClick(type: string, title: string) {
    if (this.debug)
      console.log("mixpanel firing", "search_default_click", {
        type: type,
        title: title,
      });
    mixpanel.track("search_default_click", {
      type: type.toUpperCase(),
      title: title.toUpperCase(),
    });
  }

  storeFeedClick(parameters: {
    type: "banner" | "tab" | "button";
    title: "see all" | string;
    group_name: string;
    identifier: string;
    screen_name: string;
  }) {
    mixpanel.track("store_feed_click", {
      type: parameters.type.toUpperCase(),
      title: parameters.title.toUpperCase(),
      group_name: parameters.group_name.toUpperCase(),
      identifier: parameters.identifier.toUpperCase(),
      screen_name: parameters.screen_name,
    });
  }

  productClick(
    product_id: string,
    product_name: string,
    group_name: string,
    identifier: string,
    screen_name: string,
    screen_title: string
  ) {
    if (this.debug) {
      console.log("mixpanel product_click", {
        product_id: product_id,
        product_name: product_name,
        group_name: group_name.toUpperCase(),
        identifier: identifier.toUpperCase(),
        screen_name: screen_name,
        screen_title: screen_title.toUpperCase(),
      });
    }
    mixpanel.track("product_click", {
      product_id: product_id,
      product_name: product_name,
      group_name: group_name.toUpperCase(),
      identifier: identifier.toUpperCase(),
      screen_name: screen_name,
      screen_title: screen_title.toUpperCase(),
    });
  }

  brandClick(brand_id: string, brand_name: string, screen_name: string) {
    if (this.debug)
      console.log("mixpanel brand_click", {
        brand_id: brand_id,
        brand_name: brand_name,
        screen_name: screen_name,
      });
    mixpanel.track("brand_click", {
      brand_id: brand_id,
      brand_name: brand_name,
      screen_name: screen_name,
    });
  }

  buttonClick(title: string) {
    mixpanel.track("button_click", {
      title: title.toUpperCase(),
    });
  }

  share(method: string, type: string, item_id: string) {
    if (this.debug)
      console.log("mixpanel share", {
        method: method.toUpperCase(),
        type: type.toUpperCase(),
        item_id: item_id,
      });
    mixpanel.track("share", {
      method: method.toUpperCase(),
      type: type.toUpperCase(),
      item_id: item_id,
    });
  }

  helpClick(screen_name: string, help_type: string) {
    mixpanel.track("help_click", {
      screen_name: screen_name,
      help_type: help_type.toUpperCase(),
    });
  }

  contentClick(
    page_type: string,
    button_type: string,
    seller_id: number | string,
    content_id: number | string
  ) {
    if (this.debug)
      console.log("mixpanel content_click", {
        page_type: page_type.toUpperCase(),
        button_type: button_type.toUpperCase(),
        seller_id: seller_id,
        content_id: content_id,
      });
    mixpanel.track("content_click", {
      page_type: page_type.toUpperCase(),
      button_type: button_type.toUpperCase(),
      seller_id: seller_id,
      content_id: content_id,
    });
  }

  campaignClick(name: string, type: string, page_type: string) {
    if (this.debug)
      console.log("mixpanel campaign_click", {
        name: name,
        type: type.toUpperCase(),
        page_type: page_type,
      });
    mixpanel.track("campaign_click", {
      name: name,
      type: type.toUpperCase(),
      page_type: page_type,
    });
  }

  // ==============
  // MISC FUNCTIONS
  // ==============

  private ensurePeople() {
    // Get people data from local storage
    const people = JSON.parse(localStorage.getItem("mixpanelPeople") || "{}");

    // Check if any attribute is missing
    if (
      !(
        people.$avatar &&
        people.$name &&
        people.$email &&
        people.$phone &&
        people.Date_of_birth
      )
    ) {
      // fetch people from backend
      requestAPI(Endpoints.myPage, {
        parameters: {},
        onSuccess: (response: BaseResponse<MyPageResponse>) => {
          // update missing attributes
          const updatedPeople = {
            ...people,
            $avatar: response.data.customer.profileImageUrl,
            $name: response.data.customer.username,
            $email: response.data.customer.email,
            $phone: response.data.customer.phoneNumber,
            Date_of_birth: response.data.customer.dob,
          };

          // Update the local storage with the updated attributes
          localStorage.setItem("mixpanelPeople", JSON.stringify(updatedPeople));

          // update people
          mixpanel.people.set("$avatar", updatedPeople.$avatar);
          mixpanel.people.set("$name", updatedPeople.$name);
          mixpanel.people.set("$email", updatedPeople.$email);
          mixpanel.people.set("$phone", updatedPeople.$phone);
          mixpanel.people.set("Date of birth", updatedPeople.Date_of_birth);
        },
        onFailure: (error) => {
          console.error("error fetching people", error);
        },
      });
    }
  }

  private ensureSuperProperties() {
    console.log("ensuring super properties");

    // Retrieve attributes from local storage
    const superProperties = JSON.parse(
      localStorage.getItem("mixpanelSuperProperties") || "{}"
    );

    // Check if any attribute is missing
    if (
      !(
        superProperties.distinct_id &&
        superProperties.registered_user !== undefined &&
        superProperties.platform &&
        superProperties.device_id &&
        superProperties.device_model &&
        superProperties.app_version &&
        superProperties.user_type &&
        superProperties.language
      )
    ) {
      // Fetch and update missing attributes
      const updatedSuperProperties = {
        ...superProperties,
        distinct_id: this.getCn(),
        registered_user: Boolean(localStorage.getItem("fpAuth")),
        platform: "Web",
        device_id: getDeviceId(),
        device_model: getDeviceBrandAndModel(),
        app_version: "1.0.0",
        user_type: isResellerDomain() ? "customer_semo" : "customer_thefepi",
        language: "id",
      };

      // Update the local storage with the updated attributes
      localStorage.setItem(
        "mixpanelSuperProperties",
        JSON.stringify(updatedSuperProperties)
      );

      // Update Mixpanel super properties
      mixpanel.register(updatedSuperProperties);
    }
  }

  getCn(): string | undefined {
    // Check if 'cn' is in localStorage
    const cnFromLocalStorage = localStorage.getItem("cn");

    if (cnFromLocalStorage) {
      if (this.debug)
        console.log("use cn from localStorage", cnFromLocalStorage);
      return cnFromLocalStorage;
    } else {
      // Fetch preloading data from the backend
      requestAPI(Endpoints.preloading, {
        onSuccess: (response: any) => {
          var cn = "";
          const deviceId = getDeviceId();

          // If distinct id exist from preloading
          if (response.data.distinctId) {
            // Decode distinctId using base64
            const decodedDistinctId = atob(response.data.distinctId);

            // Extract the 'cn' portion (assuming 'cn' is separated by a delimiter)
            cn = decodedDistinctId.slice(
              0,
              decodedDistinctId.indexOf(deviceId || "")
            );
          } else {
            cn = deviceId || "";
          }

          // Check if distinct id matches with CN
          if (response.data.distinctId !== cn && cn) {
            // Debug
            if (this.debug) console.log("cn is different", cn);

            // Store 'cn' in localStorage for future use
            localStorage.setItem("cn", cn);
            return cn;
          } else {
            // Debug
            if (this.debug) console.log("cn is same", cn);

            // Use getDeviceId from Utils.tsx and store 'cn' in localStorage
            const deviceId = getDeviceId() || "";
            localStorage.setItem("cn", deviceId);
            return deviceId;
          }
        },
        onFailure: (error) => {
          console.warn("error retrieving cn");
          return null;
        },
      });
    }
  }

  getGroupByUrl(url: string = window.location.href): string {
    const parsedUrl = new URL(url);
    const searchParams = parsedUrl.searchParams;
    const categoryId = searchParams.get("categoryId");
    const groupId = searchParams.get("groupId");
    const pathSegments = parsedUrl.pathname.split("/").filter(Boolean);

    if (categoryId) {
      return categoryId;
    } else if (groupId) {
      return groupId;
    } else if (pathSegments.length > 0) {
      return pathSegments[pathSegments.length - 1];
    } else {
      return "";
    }
  }

  getPageNameByUrl(url: string = window.location.href): string {
    // Use the URL constructor to extract the path from the URL
    const path = new URL(url).pathname;

    if (path.startsWith("/Store/Brands")) {
      return "SearchBrands";
    } else if (path.startsWith("/Store/Products")) {
      if (path.includes("/Store/Products/")) {
        return "StoreProductList";
      } else {
        return "StoreProductGroupMain";
      }
    } else if (path.startsWith("/Store/Search")) {
      return "Search";
    } else if (path.startsWith("/Brands")) {
      return "SearchBrands";
    } else if (path.startsWith("/Brand/")) {
      return "BrandHome";
    } else {
      // Default to 'Store' if none of the above patterns match
      return "Store";
    }
  }

  getPageNameByUrlV2(url: string = window.location.pathname): string {
    if (url === "/SizeChart") {
      return "SizeChart";
    } else if (url.startsWith("/Product/")) {
      return "ProductDetail";
    } else if (url.startsWith("/Point")) {
      return "Point";
    } else if (url.startsWith("/Payment")) {
      return "Payment";
    } else if (url.startsWith("/OrderReceipt")) {
      return "OrderReceipt";
    } else if (url.startsWith("/Vouchers")) {
      return "Coupon";
    } else if (url.startsWith("/Checkout")) {
      return "Checkout";
    } else if (url.startsWith("/Cart")) {
      return "Cart";
    } else if (url.startsWith("/OrderDetail")) {
      return "OrderDetail";
    } else {
      return "";
    }
  }
}

export default MixpanelManager;
