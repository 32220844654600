// 🌟 "In hope of restoring TheFepi's glory, we embark on this journey of code and creativity." 🚀
// 💡 Let our combined efforts shine bright like a thousand stars! 💫
// 👨‍💻 Cheers to the last week of contribution by wikan! 🙌
// 🌐 We add React Helmet to enhance SEO performance and enable captivating image previews when links are shared. 🚀🔍📸

import React from "react";
import { Helmet } from "react-helmet-async";

type SEOProps = {
  title?: string;
  description?: string;
  type?: string;
  image?: string;
  children?: React.ReactNode;
};

export default function SEO({
  title,
  description,
  type,
  image,
  children,
}: SEOProps) {
  return (
    <Helmet prioritizeSeoTags>
      {/* Standard tags */}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}

      {/*  Open Graph / Facebook */}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {type && <meta property="og:type" content={type} />}
      {image && <meta property="og:image" content={image} />}

      {/* Twitter tags */}
      {title && <meta property="twitter:title" content={title} />}
      {description && (
        <meta property="twitter:description" content={description} />
      )}
      {image && <meta property="twitter:image" content={image} />}

      {children}
    </Helmet>
  );
}
