import React from "react";
import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent";

import Scaffold, {
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";

import CreatorRegistrationViewModel from "./CreatorRegistrationViewModel";

import { Navigate } from "react-router-dom";
import "./CreatorRegistration.css";

import ButtonWidget, {
  ButtonWidgetType,
} from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import RadioButtonWidget from "../../CommonComponents/UI/RadioButtonWidget/RadioButtonWidget";
import InputFieldCreatorRegistrationWidget from "./Widgets/InputFieldCreatorRegistrationWidget";
import mixpanel from "mixpanel-browser";
import { isResellerDomain } from "../../CommonComponents/Utils";

export default class CreatorRegistrationScreen extends BaseUIComponent<
  {},
  CreatorRegistrationViewModel
> {
  constructor(props: {}) {
    super(props, new CreatorRegistrationViewModel());

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "CreatorRegistration",
    });
  }

  handleSubmit = (event: any) => {
    this.viewModel().onSubmit();
  };

  handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onEmailChanged(event.target.value);
  };

  handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onNameChanged(event.target.value);
  };

  handleChangeDOB = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onDOBChanged(event.target.value);
  };

  handleChangeMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onMobileChanged(event.target.value);
  };

  handleChangeSosmedCheckIG = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onSosmedCheckIGChanged(event.target.checked);
  };

  handleChangeSosmedCheckTiktok = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.viewModel().onSosmedCheckTiktokChanged(event.target.checked);
  };

  handleChangeSosmedIG = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onSosmedIGChanged(event.target.value);
  };

  handleChangeSosmedTiktok = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onSosmedTiktokChanged(event.target.value);
  };

  handleChangeBrandLike = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onBrandLikeChanged(event.target.value);
  };

  handleChangeShopOutcome = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel().onShopOutcomeChanged(event.target.value);
  };

  handleFileUpload = (file: string | ArrayBuffer) => {
    this.viewModel().onFileUploadChanged(file);
  };

  render() {
    if (this.viewModel().isSuccess) {
      return (
        <Navigate to={"/CreatorRegistrationSuccess/" + this.viewModel().cn} />
      );
    }

    return (
      <Scaffold
        showSnackbar={
          this.viewModel().errorMessage
            ? new ShowSnackbarModel({
                message: this.viewModel().errorMessage ?? "",
              })
            : undefined
        }
      >
        <div id="CreatorRegistration" className="container">
          <div id="CreatorNavBar">
            Thefepi Creator Application
            <img
              className="img-fluid"
              src={
                global.ImgUrl +
                "shopable/30CA5E6E-3DE1-4A79-950F-64A10AD29652.svg"
              }
              alt="gambar2"
            ></img>
          </div>

          <h5>
            <b>Halo!</b>👋{" "}
            <b>
              Silakan isi form berikut supaya kami bisa mengenal kamu lebih
              dalam.
            </b>
          </h5>
          <span className="red">*required field</span>
          <br></br>
          <br></br>

          <div className="groupQuestion">
            <div className="row">
              <div className="col">
                <p className="question">
                  Email kamu? <span className="red">*</span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <InputFieldCreatorRegistrationWidget
                  class="InputWidgetCreatorRegistration"
                  inputType="email"
                  placeholder="Input your email"
                  errorMessage={this.viewModel().errorMessageEmail}
                  action={(event) => {
                    this.handleChangeEmail(event);
                  }}
                ></InputFieldCreatorRegistrationWidget>
              </div>
            </div>
          </div>

          <div className="groupQuestion">
            <div className="row">
              <div className="col">
                <p className="question">
                  Nama kamu? <span className="red">*</span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <InputFieldCreatorRegistrationWidget
                  class="InputWidgetCreatorRegistration"
                  inputType="text"
                  placeholder="Input your Name"
                  errorMessage={this.viewModel().errorMessageName}
                  action={(event) => {
                    this.handleChangeName(event);
                  }}
                ></InputFieldCreatorRegistrationWidget>
              </div>
            </div>
          </div>

          <div className="groupQuestion">
            <div className="row">
              <div className="col">
                <p className="question">
                  Tanggal lahir kamu? <span className="red">*</span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <InputFieldCreatorRegistrationWidget
                  class="InputWidgetCreatorRegistration"
                  inputType="date"
                  placeholder="Input your DOB"
                  errorMessage={this.viewModel().errorMessageDOB}
                  action={(event) => {
                    this.handleChangeDOB(event);
                  }}
                ></InputFieldCreatorRegistrationWidget>
              </div>
            </div>
          </div>

          <div className="groupQuestion">
            <div className="row">
              <div className="col">
                <p className="question">
                  No. HP kamu? <span className="red">*</span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <InputFieldCreatorRegistrationWidget
                  class="InputWidgetCreatorRegistration"
                  inputType="number"
                  placeholder="Input your Phone"
                  errorMessage={this.viewModel().errorMessageMobile}
                  action={(event) => {
                    this.handleChangeMobile(event);
                  }}
                ></InputFieldCreatorRegistrationWidget>
              </div>
            </div>
          </div>

          <div className="groupQuestion">
            <div className="row">
              <div className="col">
                <p className="question">
                  Brand-brand fashion yang kamu suka adalah...?{" "}
                  <span className="red">*</span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <InputFieldCreatorRegistrationWidget
                  class="InputWidgetCreatorRegistration"
                  inputType="text"
                  placeholder="Input your brand you like"
                  errorMessage={""}
                  action={(event) => {
                    this.handleChangeBrandLike(event);
                  }}
                ></InputFieldCreatorRegistrationWidget>
              </div>
            </div>
          </div>

          <div className="groupQuestion">
            <div className="row">
              <div className="col">
                <p className="question">
                  Berapa banyak pengeluaran belanja kamu? (per bulan)?{" "}
                  <span className="red">*</span>
                </p>
              </div>
            </div>
            <div className="answer">
              <RadioButtonWidget
                title="< 500 ribu"
                inputType="radio"
                value={1}
                name="radio_shopping_outcome"
                isRequired={true}
                action={(event) => {
                  this.handleChangeShopOutcome(event);
                }}
              ></RadioButtonWidget>
            </div>
            <div className="answer">
              <RadioButtonWidget
                title="500 rb - 1 juta"
                inputType="radio"
                value={2}
                name="radio_shopping_outcome"
                isRequired={true}
                action={(event) => {
                  this.handleChangeShopOutcome(event);
                }}
              ></RadioButtonWidget>
            </div>
            <div className="answer">
              <RadioButtonWidget
                title="1 - 3 juta"
                inputType="radio"
                value={3}
                name="radio_shopping_outcome"
                isRequired={true}
                action={(event) => {
                  this.handleChangeShopOutcome(event);
                }}
              ></RadioButtonWidget>
            </div>
            <div className="answer">
              <RadioButtonWidget
                title="3 - 5 juta"
                inputType="radio"
                value={4}
                name="radio_shopping_outcome"
                isRequired={true}
                action={(event) => {
                  this.handleChangeShopOutcome(event);
                }}
              ></RadioButtonWidget>
            </div>
            <div className="answer">
              <RadioButtonWidget
                title="5 - 10 juta"
                inputType="radio"
                value={5}
                name="radio_shopping_outcome"
                isRequired={true}
                action={(event) => {
                  this.handleChangeShopOutcome(event);
                }}
              ></RadioButtonWidget>
            </div>
            <div className="answer">
              <RadioButtonWidget
                title="10 - 20 juta"
                inputType="radio"
                value={6}
                name="radio_shopping_outcome"
                isRequired={true}
                action={(event) => {
                  this.handleChangeShopOutcome(event);
                }}
              ></RadioButtonWidget>
            </div>
            <div className="answer">
              <RadioButtonWidget
                title="> 20 juta"
                inputType="radio"
                value={7}
                name="radio_shopping_outcome"
                isRequired={true}
                action={(event) => {
                  this.handleChangeShopOutcome(event);
                }}
              ></RadioButtonWidget>
            </div>
          </div>

          <div className="groupQuestion">
            <p className="question mtop10">
              Mana saja yang kamu pakai? <span className="red">*</span>
            </p>
            <div className="answer">
              <RadioButtonWidget
                title="Instagram"
                inputType="checkbox"
                name="checkbox_sosmed[]"
                isRequired={true}
                action={(event) => {
                  this.handleChangeSosmedCheckIG(event);
                }}
              ></RadioButtonWidget>
            </div>
            <div className="answer">
              <InputFieldCreatorRegistrationWidget
                inputType="text"
                placeholder="Input Your Instagram Account"
                errorMessage={""}
                isEnabled={this.viewModel().sosmed_check_ig}
                class="InputOtherWidgetCreatorRegistration"
                action={(event) => {
                  this.handleChangeSosmedIG(event);
                }}
              ></InputFieldCreatorRegistrationWidget>
            </div>
            <div className="answer">
              <RadioButtonWidget
                title="Tiktok"
                inputType="checkbox"
                value={2}
                name="checkbox_sosmed[]"
                isRequired={true}
                action={(event) => {
                  this.handleChangeSosmedCheckTiktok(event);
                }}
              ></RadioButtonWidget>
            </div>
            <div className="answer">
              <InputFieldCreatorRegistrationWidget
                inputType="text"
                placeholder="Input Your Tiktok Account"
                errorMessage={""}
                isEnabled={this.viewModel().sosmed_check_tiktok}
                class="InputOtherWidgetCreatorRegistration"
                action={(event) => {
                  this.handleChangeSosmedTiktok(event);
                }}
              ></InputFieldCreatorRegistrationWidget>
            </div>
          </div>

          <div className="groupQuestion">
            <div className="row">
              <div className="col">
                <p className="question">
                  Upload foto OOTD paling keren kamu!{" "}
                  <span className="red">*</span>
                </p>
              </div>
            </div>
            <div className="answer">
              <InputFieldCreatorRegistrationWidget
                class="InputWidgetCreatorRegistration"
                inputType="file"
                errorMessage={""}
                action={(event) => {
                  let files = event.target.files ?? [];
                  let fileReader = new FileReader();

                  fileReader.readAsDataURL(files[0]);

                  fileReader.onload = (event) => {
                    const result = event.target?.result;

                    if (result) {
                      this.handleFileUpload(result);
                    }
                  };
                }}
              ></InputFieldCreatorRegistrationWidget>
            </div>
          </div>

          <div className="row">
            <div className="col mtop22 text-center">
              <ButtonWidget
                buttonType={ButtonWidgetType.primary}
                isLoading={
                  this.viewModel().isLoadingButtonSubmit ? true : false
                }
                isEnabled={
                  this.viewModel().email !== "" &&
                  this.viewModel().name !== "" &&
                  this.viewModel().dob !== "" &&
                  this.viewModel().mobile !== "" &&
                  this.viewModel().brand_like !== "" &&
                  this.viewModel().shop_outcome !== "" &&
                  this.viewModel().file_upload !== "" &&
                  this.viewModel().isClearValidSosmed
                    ? true
                    : false
                }
                width="calc(100% - 6px)"
                action={() => {
                  this.handleSubmit("");
                }}
              >
                Next
              </ButtonWidget>
            </div>
          </div>

          <div className="row">
            <div className="col mtop22 text-center"></div>
          </div>
        </div>
      </Scaffold>
    );
  }
}
