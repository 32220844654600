import React from 'react';
import Scaffold from '../../CommonComponents/UI/Scaffold/Scaffold';
import mixpanel from 'mixpanel-browser';
import { isResellerDomain } from '../../CommonComponents/Utils';

const url = window.location.href;
const cn = url.substring(url.lastIndexOf('/') + 1);

class CreatorRecruitingScreen extends React.Component {
    constructor() {
        //MIXPANEL TRACKING: semo_page_view / screen_view
        mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
            page_name: "CreatorRecruiting",
        });
    }

    state = {
        showResults1: true,
        showResults1a: true,
        showResults2: false,
        showResults2a: false,
        showResults3: false,
        showResults3a: false,
        showResults4: false,
        showResults4a: false,
        showResults5: false,
        showResults5a: false
    };


    showTerms = e => {
        //$('#FAQModal').modal('hide');
    }

    onClick = param => e => {
        if (param === 1) {
            this.setState({
                showResults1: true,
                showResults1a: true,
                showResults2: false,
                showResults2a: false,
                showResults3: false,
                showResults3a: false,
                showResults4: false,
                showResults4a: false,
                showResults5: false,
                showResults5a: false,
                showResults6: false,
                showResults6a: false
            });
        }
        if (param === 2) {
            this.setState({
                showResults1: false,
                showResults1a: false,
                showResults2: true,
                showResults2a: true,
                showResults3: false,
                showResults3a: false,
                showResults4: false,
                showResults4a: false,
                showResults5: false,
                showResults5a: false,
                showResults6: false,
                showResults6a: false
            });
        }
        if (param === 3) {
            this.setState({
                showResults1: false,
                showResults1a: false,
                showResults2: false,
                showResults2a: false,
                showResults3: true,
                showResults3a: true,
                showResults4: false,
                showResults4a: false,
                showResults5: false,
                showResults5a: false,
                showResults6: false,
                showResults6a: false
            });
        }
        if (param === 4) {
            this.setState({
                showResults1: false,
                showResults1a: false,
                showResults2: false,
                showResults2a: false,
                showResults3: false,
                showResults3a: false,
                showResults4: true,
                showResults4a: true,
                showResults5: false,
                showResults5a: false,
                showResults6: false,
                showResults6a: false
            });
        }
        if (param === 5) {
            this.setState({
                showResults1: false,
                showResults1a: false,
                showResults2: false,
                showResults2a: false,
                showResults3: false,
                showResults3a: false,
                showResults4: false,
                showResults4a: false,
                showResults5: true,
                showResults5a: true,
                showResults6: false,
                showResults6a: false
            });
        }
        if (param === 6) {
            this.setState({
                showResults1: false,
                showResults1a: false,
                showResults2: false,
                showResults2a: false,
                showResults3: false,
                showResults3a: false,
                showResults4: false,
                showResults4a: false,
                showResults5: false,
                showResults5a: false,
                showResults6: true,
                showResults6a: true
            });
        }
    };

    render() {
        return (
            <Scaffold>
                <div className="container" id="jastip_content">
                    <div className="row">
                        <div className="col text-center">
                            <p style={{ fontSize: '34px', marginTop: '10px' }}>😎</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <p style={{ fontSize: '20px', marginTop: '10px' }}><strong>Bagikan Style Favoritmu, <br></br> Dapat Teman, Dapat Cuan!</strong></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <p style={{ fontSize: '13px' }}><span style={{ backgroundColor: '#ffffa5' }}>Bagikan</span> ke semua orang foto style terbaikmu dan <br></br><span style={{ backgroundColor: '#bfc5ff' }}>dapatkan</span> penghasilan tambahan</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <img className="img-fluid" src={global.ImgUrl + 'shopable/img-seller-01@3x.png'} alt="gambar1"></img>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <p style={{ fontSize: '14px' }}><b>Punya style fashion yang keren dan hobi mix <br></br>
                                and match outfitmu? Jangan cuma posting foto <br></br>
                                aja! <span style={{ backgroundColor: '#b8ffdb' }}>Gabung sebagai creator di THEFEPI</span> dan <br></br>
                                <span style={{ backgroundColor: '#bfc5ff' }}>dapatkan keuntungan</span> dari style fashionmu!</b></p>
                        </div>
                    </div>

                    <div className="row ptop30">
                        <div className="col text-center box_purple2">
                            <h1>📸</h1>
                            <p style={{ fontSize: '20px', fontFamily: 'SF Pro Bold' }}>Merasa Trendy Hari Ini?<br></br>Take a Pic Dulu!</p>
                            <span><b>Pergi ke kampus dengan <br></br> <span style={{ fontSize: '14', fontFamily: 'SF Pro Bold', backgroundColor: '#ffffa5' }}>sneakers keren?</span> </b></span>
                            <br></br>
                            <span style={{ fontSize: '14', fontFamily: 'SF Pro Bold', backgroundColor: '#FFF' }}><b>Snap 📸</b></span>
                            <img className="img-fluid" src={global.ImgUrl + 'shopable/img-seller-02@2x.png'} alt="gambar2"></img>
                            <span><b>Pergi meeting dengan outfit <br></br><span style={{ fontSize: '14', fontFamily: 'SF Pro Bold', backgroundColor: '#b8ffdb' }}>preppy style?</span> </b></span>
                            <br></br>
                            <span style={{ fontSize: '14', fontFamily: 'SF Pro Bold', backgroundColor: '#FFF' }}><b>Snap 📸</b></span>
                            <img className="img-fluid" src={global.ImgUrl + 'shopable/img-seller-03@2x.png'} alt="gambar3"></img>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <p style={{ fontSize: '34px', marginTop: '60px' }}>😍</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <p style={{ fontSize: '20px', marginTop: '10px' }}><strong>Jadikan Stylemu Diinginkan <br></br>Semua Orang!</strong></p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <span style={{ fontSize: '17px', fontFamily: 'SF Pro Bold' }}>Dapatkan semakin <span style={{ fontSize: '14', fontFamily: 'SF Pro Bold', backgroundColor: '#ffffa5' }}>banyak komisi </span><br></br>melalui <span style={{ fontSize: '14', fontFamily: 'SF Pro Bold', backgroundColor: '#b8ffdb' }}>foto stylemu</span> yang shining- <br></br>shimmering-splendid✨</span>  <br></br>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <span style={{ fontSize: '15px', color: '#616eff', fontWeight: 'bold' }}>
                                <br></br>
                                🙋‍♂️Psst, jangan lupa rajin upload di akun THEFEPI-mu agar makin dinotice
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <br></br>
                            <br></br>
                            <img className="img-fluid" src={global.ImgUrl + 'shopable/img-seller-04@2x.png'} alt="gambar4"></img>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <p style={{ fontSize: '34px', marginTop: '60px' }}>🚀</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <p style={{ fontSize: '20px', marginTop: '10px' }}><strong>Bagikan Link dan Dapatkan <br></br>Penghasilan Tambahan</strong></p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <span style={{ fontSize: '17px', fontFamily: 'SF Pro Bold' }}>Semudah <span style={{ backgroundColor: '#ffffa5' }}>membagikan link</span> tokomu ke <br></br>TikTok, IG dan Youtube</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <br></br>
                            <br></br>
                            <span style={{ fontSize: '15px', fontFamily: 'SF Pro Bold', color: '#da61ff' }}>🙋‍♀️bantu orang lain mendapatkan outfit<br></br> impiannya dari stylemu fashionmu.</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <br></br>
                            <br></br>
                            <img className="img-fluid" src={global.ImgUrl + 'shopable/img-seller-05@2x.png'} alt="gambar5"></img>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-center box_purple2">
                            <br></br>
                            <img width="40" src={global.ImgUrl + 'fire.png'} alt="fire icon"></img>
                            <p style={{ fontSize: '20px', marginTop: '10px' }}><strong>Keuntungan THEFEPI Creator</strong></p>
                            <br></br>

                            <div className="box_putih_recruting">
                                <ul className="list-inline">
                                    <li className="list-inline-item"><span style={{ fontSize: '13px' }}>💵<strong>Menawarkan komisi hingga jutaan rupiah <span style={{ backgroundColor: '#ffffa5' }}>(5%)</span></strong></span></li>
                                </ul>
                            </div>
                            <div className="box_putih_recruting">
                                <ul className="list-inline">
                                    <li className="list-inline-item"><span style={{ fontSize: '13px' }}>✔️<strong>Produk dijamin <span style={{ backgroundColor: '#b8ffdb' }}>100% ORIGINAL</span></strong></span></li>
                                </ul>
                            </div>
                            <div className="box_putih_recruting">
                                <ul className="list-inline">
                                    <li className="list-inline-item"><span style={{ fontSize: '13px' }}>📦<strong>Tidak perlu memiliki <span style={{ backgroundColor: '#bfc5ff' }}>stock produk</span></strong></span></li>
                                </ul>
                            </div>
                            <div className="box_putih_recruting">
                                <ul className="list-inline">
                                    <li className="list-inline-item"><span style={{ fontSize: '13px' }}>🚚<strong>Tidak perlu sibuk <span style={{ backgroundColor: '#bfc5ff' }}>packing dan mengirim</span> produk</strong></span></li>
                                </ul>
                            </div>
                            <div className="box_putih_recruting">
                                <ul className="list-inline">
                                    <li className="list-inline-item"><span style={{ fontSize: '13px' }}>📸<strong>Cukup <span style={{ backgroundColor: '#ffffa5' }}>unggah foto</span> style terbaikmu and <span style={{ backgroundColor: '#ffffa5' }}>promote</span> on your social media!</strong></span></li>
                                </ul>
                            </div>
                            <div className="box_putih_recruting">
                                <ul className="list-inline">
                                    <li className="list-inline-item"><span style={{ fontSize: '13px' }}>🤩<strong>Ditangani oleh <span style={{ backgroundColor: '#b8ffdb' }}>customer service</span> yang profesional</strong></span></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <br></br>
                            <br></br>
                            <span style={{ fontSize: '20px', fontFamily: 'SF Pro Bold' }}>🤔Bagaimana Cara Gabung<br></br> Sebagai Creator THEFEPI?🤔</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <br></br>
                            <br></br>
                            <img width="100" src={global.ImgUrl + 'shopable/4DE58260-FB7F-4E6A-B0D4-5256ABF6D1C3.png'} alt="gambar4"></img>
                            {
                                cn === "CreatorRecruiting" ?
                                    <p style={{ fontSize: '18px', fontFamily: 'SF Pro Bold', color: '#001150' }}><span style={{ backgroundColor: '#ffffa5' }}>Daftarkan</span> diri sebagai creator <br></br>THEFEPI <a href="/CreatorRegistration" style={{ color: '#0067ff', textDecoration: 'underline' }}>disini</a></p>
                                    :
                                    <p style={{ fontSize: '18px', fontFamily: 'SF Pro Bold', color: '#001150' }}><span style={{ backgroundColor: '#ffffa5' }}>Daftarkan</span> diri sebagai creator <br></br>THEFEPI <a href={"/CreatorRegistration" + cn} style={{ color: '#0067ff', textDecoration: 'underline' }}>disini</a></p>
                            }

                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <img width="100" src={global.ImgUrl + 'shopable/FD311ECE-9B92-4518-A729-4A80E78E86DF.png'} alt="gambar4"></img>
                            <p style={{ fontSize: '18px', fontFamily: 'SF Pro Bold', color: '#001150' }}><span style={{ backgroundColor: '#ffffa5' }}>Tunggu email</span> konfirmasi dalam<br></br> 7 hari kerja</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <img width="100" src={global.ImgUrl + 'shopable/282772AD-C1F5-482D-9072-56ED66BA6904.png'} alt="gambar4"></img>
                            <p style={{ fontSize: '18px', fontFamily: 'SF Pro Bold', color: '#001150' }}><span style={{ backgroundColor: '#ffffa5' }}>Unggah</span> konten menarik di <br></br>THEFEPI dan <span style={{ backgroundColor: '#ffffa5' }}>share linknya</span> di<br></br> seluruh social media untuk<br></br> mendapat komisi yang lebih<br></br> banyak!</p>
                        </div>
                    </div>
                    {
                        cn === "CreatorRecruiting" ?
                            <a href="/CreatorRegistration"><button className="flaotingRegis">DAFTAR</button></a>
                            :
                            <a href={"/CreatorRegistration/" + cn}><button className="flaotingRegis">DAFTAR</button></a>
                    }


                    <div className="row ptop30">
                        <div className="col text-center box_purple2">
                            <h1>💰💰💰</h1>
                            <p style={{ fontSize: '20px', fontFamily: 'SF Pro Bold' }}>Playground for fashion people?</p>
                            <p style={{ fontSize: '14px', fontFamily: 'SF Pro Bold' }}>Ya cuma di THEFEPI! Yuk gabung sebagai <br></br>creator sekarang!</p>
                            <br></br><br></br>
                            {
                                cn === "CreatorRecruiting" ?
                                    <a href={"/CreatorRegistration"}><button className="daftar_sekarang">DAFTAR SEKARANG</button></a>
                                    :
                                    <a href={"/CreatorRegistration/" + cn}><button className="daftar_sekarang">DAFTAR SEKARANG</button></a>
                            }

                            <br></br><br></br>
                            <button className="sfaq" data-bs-toggle="modal" data-bs-target="#FAQModal">F A Q</button>
                            <br></br><br></br>
                            <span style={{ color: '#383838' }} data-bs-toggle="modal" data-bs-target="#SellerTNCModal">Syarat dan Ketentuan</span>
                        </div>
                    </div>

                    <div className="row ptop70">
                    </div>

                    <div className="modal fade" id="SellerTNCModal" tabIndex="-1" aria-labelledby="SellerTNCModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header" style={{ position: 'fixed', top: '0px', zIndex: '99', backgroundColor: 'white', width: '99%' }}>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ marginTop: '60px' }}>
                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>SYARAT DAN KETENTUAN - THEFEPI CREATOR PROGRAM</p>

                                    <span>&lt; Ketentuan Creator Shop &gt;</span><br></br>

                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>1. Tipe konten creator</p>
                                    1.1) style: Foto styling creator diposting pada bagian feed (creator's creation)<br></br>
                                    1.2) Produk: Setiap produk yang terdaftar/ ditandai oleh creator (sama dengan produk di toko kami)<br></br>

                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}> 2. Ketentuan/Pembatasan Konten</p>
                                    2.1) Setiap foto harus, paling tidak men-tag 1 produk (Foto yang diposting tanpa tag produk, tidak diperkenankan)<br></br>
                                    2.2) Produk yang TIDAK dibeli oleh creator atau TIDAK di tag pada foto styling juga BISA dicantumkan di creator shop.<br></br>
                                    2.3) Jika jumlah produk yang dibeli & terdaftar: 10, Creator dapat mencantumkan produk yang TIDAK DIBELI hingga 10<br></br>
                                    2.4)Jika produk tidak dibeli dari kami, tetapi dijual di TOKO kami, Creator dapat men-tag produk tersebut.<br></br>
                                    2.5) Jika produk yang creator ingin tag/jual belum masuk di TOKO kami, maka TIDAK BISA untuk di tag. (Namun jika creator ingin menjual, bisa request seperti Jastip)<br></br>


                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>3. Minimum Posting untuk membuka creator shop</p>
                                    3.1) Style: Min. 1 posting atau lebih (tidak ada batas maksimal)<br></br>
                                    3.2) Produk: Min. 1 produk <br></br>
                                    3.3) Maksimal tag 5 produk dalam 1 foto style<br></br>

                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>4. Untuk produk, Total batas jumlah Tagging</p>
                                    4.1) Pembukaan awal: Sebanyak 3 creator dapat men-tag / menjual produk (First come, First Serve)<br></br>
                                    4.2) Tetapi jika creator telah membeli produk itu, penjual dapat memberi tag/menjual tanpa batas<br></br>

                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>5. Kriteria upload untuk Style by creator</p>
                                    5.1) Berdasarkan foto dimana item dalam pakaian dapat dilihat JELAS (Penuh atau Sebagian)<br></br>
                                    5.2) Resolusi yang rendah, komposisi yang buruk, atau outfit yang tidak terlihat jelas dapat ditolak<br></br>
                                    5.3) Foto yang tidak ada hubungannya dengan foto styling, konten yang tidak patut dan foto yang memakai toko/merek/produk Anda sendiri akan ditolak<br></br>

                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>6. Proses penyaringan untuk foto</p>
                                    6.1) Pembukaan awal: Akan diposting setelah konsultasi sebelumnya dengan creator terpilih<br></br>
                                    6.2) Pada dasarnya, proses 'pra-penyaringan' untuk konten yang diunggah oleh penjual DIHAPUS<br></br>
                                    6.3) Penyaringan dan panduan dilakukan secara acak oleh staff secara acak<br></br>
                                    6.4) Fungsi report akan diperkenalkan, sehingga pengguna bisa mereport gambar berbahaya secara langsung, dan staff akan meninjau terlebih dahulu gambar tersebut.<br></br>
                                    6.5) Tindakan koreksi/penghapusan gambar (pertama) {">"} bila diulang (lebih dari 3 kali), akan dilakukan tindakan peringatan/penangguhan akun<br></br>

                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>7. Creator Reward </p>
                                    7.1) Saat customer membeli produk melalui posting/tlink/pengiriman penjual, maka reward mulai dihasilkan!<br></br>
                                    7.2) Pendapatan reward: 5% dari harga produk (ditetapkan jumlahnya di pembukaan awal)<br></br>

                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>8. Persyaratan Monetisasi Reward Creator</p>
                                    8.1) Ketika customer membeli melalui creator shop, 5% dari harga jual diakui sebagai ‘Unconfirmed balance(revenue)’<br></br>
                                    8.2) Setelah ‘purchase confirmation’ (‘Order complete’) diubah menjadi ‘Withdrawable Balance(revenue)’ maka sudah dapat diuangkan<br></br>
                                    8.3) ‘Withdrawable balance’ dapat ditarik minimal di atas Rp. 50,000 (IDR)<br></br>

                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>9. Pilihan Monetisasi Reward</p>
                                    9.1) Pilihan monetisasi yang tersedia: i. Pembayaran tunai ii. SHOP Point iii. Donation<br></br>
                                    9.2) 'Cash-out' dilakukan melalui Transfer Bank - Mewajibkan informasi creator (nama, KTP, dll) - Creator memasukkan informasi Bank/Nomor Rekening /Nama pemegang rekening - Penarikan tunai setelah PPN atau pemotongan pajak 10% - Dikenakan biaya transaksi saat transfer ke bank selain bank terdaftar perusahaan kami - proses membutuhkan 3-5 hari setelah pengajuan<br></br>
                                    9.3) Jika telah dikonversi menjadi Shop point, maka tidak bisa dikembalikan menjadi uang atau membatalkan konversi<br></br>

                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>10. Skema Donasi Creator</p>
                                    10.1) Creator dapat menetapkan jumlah donasinya dalam hitungan % sebelum memulai penjualan (jika ditetapkan 0.5%, maka keuntungan penjual dari 5% menjadi 4,5%)<br></br>
                                    10.2) Detail pengaturan donasi Creator juga diberitahukan kepada customer terlebih dahulu di Creator Shop (misal, Rp.xxx akan didonasikan ke xxx)<br></br>
                                    10.3) Donasi bisa dipilih bahkan setelah proses monetisasi setelah penjualan<br></br>

                                    <p style={{ fontSize: '16px', fontFamily: 'SF Pro Bold' }}>11. Bagaimana Donasi Creator bekerja</p>
                                    11.1) Kemitraan dengan Komunitas/Kelompok Sosial terkait (misal. Perlindungan Lingkungan, Kesejahteraan Anak, Perawatan Hewan, dll)<br></br>
                                    11.2) Perusahaan mempromosikan/mengekspos progres kegiatan donasi setiap bulan secara berkala<br></br>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="FAQModal" tabIndex="-1" aria-labelledby="FAQModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ minHeight: '800px' }}>
                                    <div className="row">
                                        <div className="col text-left">
                                            <br></br>
                                            <div id="menu_main">
                                                <ul className="list-inline no-padding" >
                                                    <li className={this.state.showResults1a ? 'list-inline-item active' : 'list-inline-item'} id="menutop1"><button onClick={this.onClick(1)}>CREATOR POSTING</button></li>
                                                    <li className={this.state.showResults2a ? 'list-inline-item active' : 'list-inline-item'} id="menutop2"><button onClick={this.onClick(2)}>BECOMING A CRATOR</button></li>
                                                    <li className={this.state.showResults3a ? 'list-inline-item active' : 'list-inline-item'} id="menutop3"><button onClick={this.onClick(3)}>CONTENT</button></li>
                                                    <li className={this.state.showResults6a ? 'list-inline-item active' : 'list-inline-item'} id="menutop6"><button onClick={this.onClick(6)}>POSTING GUIDE</button></li>
                                                    <li className={this.state.showResults4a ? 'list-inline-item active' : 'list-inline-item'} id="menutop4"><button onClick={this.onClick(4)}>BENEFIT</button></li>
                                                    <li className={this.state.showResults5a ? 'list-inline-item active' : 'list-inline-item'} id="menutop5"><button onClick={this.onClick(5)}>COMMISION</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="AccordionFAQ" style={{ display: this.state.showResults1 ? "block" : "none" }}>
                                        <div className="accordion" id="myAccordion1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne1">
                                                        <strong>Apakah ada syarat dan ketentuan apabila ingin jadi creator?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne1" className="accordion-collapse collapse show" data-bs-parent="#myAccordion1">
                                                    <div className="card-body">
                                                        <p>Ada, dong! Thefepi menerima kamu yang suka menyukai dunia konten sosial media, juga para pecinta fashion yang suka sharing konten-konten mengenai fashion di sosial media mereka.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo1">
                                                        <strong>Bagaimana caranya menjadi creator?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo1" className="accordion-collapse collapse" data-bs-parent="#myAccordion1">
                                                    <div className="card-body">
                                                        <p>Kamu hanya perlu mengisi form pendaftaran menjadi creator di link ini. Nanti tim akan mengecek profil kamu terlebih dahulu. Tunggu tim menghubungi kamu ya!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree1">
                                                        <strong>Berapa jumlah foto yang harus disubmit?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseThree1" className="accordion-collapse collapse" data-bs-parent="#myAccordion1">
                                                    <div className="card-body">
                                                        <p>Setelah mengisi form aplikasi. tim Thefepi akan menghubungi kamu untuk berdiskusi mengenai jumlah submission, ya.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFour">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour1">
                                                        <strong>Kok, aku tidak diterima menjadi creator?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseFour1" className="accordion-collapse collapse" data-bs-parent="#myAccordion1">
                                                    <div className="card-body">
                                                        <p>Boleh jadi kamu termasuk dalam kondisi-kondisi berikut:<br></br>
                                                            - Konten media sosial kamu kurang banyak, sehingga tidak bisa kami review<br></br>
                                                            - Resolusi atau komposisi gambar kamu sulit diidentifikasi<br></br>
                                                            - Akun kamu masih di-private</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="AccordionFAQ" style={{ display: this.state.showResults2 ? "block" : "none" }}>
                                        <div className="accordion" id="myAccordion2">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne2">
                                                        <strong>Apakah harus membeli item di The Fepi dulu?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne2" className="accordion-collapse collapse" data-bs-parent="#myAccordion2">
                                                    <div className="card-body">
                                                        <p>Kamu bisa menggunakan item yang sudah kamu punya, kok. Asalkan item kamu juga tersedia di website The Fepi. (Tips: kamu bisa banget pinjam teman dan keluarga!)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo2">
                                                        <strong>Berapa banyak pembeli yang harus dicapai?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo2" className="accordion-collapse collapse" data-bs-parent="#myAccordion2">
                                                    <div className="card-body">
                                                        <p>Sebanyak yang kamu bisa! Maka dari itu, kamu perlu menjadi kreatif sehingga kamu bisa mempresentasikan dirimu sebaik mungkin supaya orang-orang tertarik membeli dari kamu.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree2">
                                                        <strong>Bagaimana caranya memproses pembelian ketika ada yang membeli dari link aku?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseThree2" className="accordion-collapse collapse" data-bs-parent="#myAccordion2">
                                                    <div className="card-body">
                                                        <p>Setelah mengisi form aplikasi. tim Thefepi akan menghubungi kamu untuk berdiskusi mengenai jumlah submission, ya.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFour">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour2">
                                                        <strong>Gimana caranya supaya teman-teman tahu kalau aku creator Thefepi?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseFour2" className="accordion-collapse collapse" data-bs-parent="#myAccordion2">
                                                    <div className="card-body">
                                                        <p>Kamu bisa kirim link Shopble kamu ke grup chat, Instagram, TikTok, Twitter, bahkan Youtube. Pastikan kamu kirim link sebanyak-banyaknya, ya!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="AccordionFAQ" style={{ display: this.state.showResults3 ? "block" : "none" }}>
                                        <div className="accordion" id="myAccordion3">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne3">
                                                        <strong>Apa saja ketentuan untuk upload foto-fotonya?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne3" className="accordion-collapse collapse" data-bs-parent="#myAccordion3">
                                                    <div className="card-body">
                                                        <p>Kamu cuma perlu pastikan kalau foto-foto tersebut diambil dengan baik dan resolusinya tinggi, ya.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo3">
                                                        <strong>Kok, foto-fotoku tidak diterima?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo3" className="accordion-collapse collapse" data-bs-parent="#myAccordion3">
                                                    <div className="card-body">
                                                        <p>Bisa jadi karena foto kamu:<br></br>
                                                            - Nge-blur dan tidak terlihat secara detail<br></br>
                                                            - Bukan konten yang terkait fashion<br></br>
                                                            - Resolusinya rendah<br></br>
                                                            - Mempromosikan brand pribadi dan tidak menggunakan item yang tersedia di The Fepi</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="AccordionFAQ" style={{ display: this.state.showResults4 ? "block" : "none" }}>
                                        <div className="accordion" id="myAccordion4">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne4">
                                                        <strong>Apa keuntungan menjadi creator di Thefepi?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne4" className="accordion-collapse collapse" data-bs-parent="#myAccordion4">
                                                    <div className="card-body">
                                                        <p>Banyak! Kamu bisa dapat 5% tiap kali ada pembeli yang melakukan pembelian melalui link kamu. Komisi tersebut akan langsung masuk ke akun kamu ketika si pembeli sudah menyelesaikan pembelian mereka. Kamu bisa menarik komisi tersebut dengan min. Saldo IDR 50,000,-, ya. Untuk info lebih lanjut, kamu bisa klik <a data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal" data-bs-target="#SellerTNCModal" href="#;" style={{ textDecoration: 'underline', color: 'blue' }}> Syarat dan Ketentuan</a>.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo4">
                                                        <strong>Apakah ada keuntungan apabila berbelanja dari Thefepi?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo4" className="accordion-collapse collapse" data-bs-parent="#myAccordion4">
                                                    <div className="card-body">
                                                        <p>Ada, dong! Kamu akan langsung mendapatkan voucher-voucher tanpa minimum pembelian ketika kamu bergabung.<br></br>
                                                            - Diskon 10%<br></br>
                                                            - Potongan 50k<br></br>
                                                            - Gratis ongkir.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree4">
                                                        <strong>Kok, voucher-voucher ku hilang?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseThree4" className="accordion-collapse collapse" data-bs-parent="#myAccordion4">
                                                    <div className="card-body">
                                                        <p>Apabila kamu tidak meng-upload konten-konten fashion dalam jangka waktu 10 hari, atau ketika kamu menjual kembali item-item tersebut, kamu akan menadapat penalti. Hal ini menyebabkan kamu kehilangan keuntungan-keutungan kamu.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="AccordionFAQ" style={{ display: this.state.showResults5 ? "block" : "none" }}>
                                        <div className="accordion" id="myAccordion5">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne5">
                                                        <strong>Bagaimana caranya mengecek detail komisi?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne5" className="accordion-collapse collapse" data-bs-parent="#myAccordion5">
                                                    <div className="card-body">
                                                        <p>Kamu akan mendapatkan komisi setelah pembeli kamu menyelesaikan pesanannya.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo5">
                                                        <strong>Ada pembeli yang membeli item melalui link aku. Kok, aku belum dapat komisi?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo5" className="accordion-collapse collapse" data-bs-parent="#myAccordion5">
                                                    <div className="card-body">
                                                        <p>Kamu akan dapat komisi setelah pembelian orang tersebut sudah selesai. Maksudnya, pembeli tersebut sudah membayar dan menerima itemnya.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree5">
                                                        <strong>Kapan aku bisa mencairkan komisi?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseThree5" className="accordion-collapse collapse" data-bs-parent="#myAccordion5">
                                                    <div className="card-body">
                                                        <p>Registrasi terlebih dahulu, lalu kamu bisa mencairkan komisi dengan pencairan minimum 50k</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFour">
                                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour5">
                                                        <strong>Registrasi terlebih dahulu, lalu kamu bisa mencairkan komisi dengan pencairan minimum 50k</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseFour5" className="accordion-collapse collapse" data-bs-parent="#myAccordion5">
                                                    <div className="card-body">
                                                        <p>Tim Thefepi memerlukan data KTP, NPWP, dan rekening bank. Biaya transfer bank dan pajak 10% akan dipotong dari komisi.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="AccordionFAQ" style={{ display: this.state.showResults6 ? "block" : "none" }}>
                                        <div className="row">
                                            <div className="col text-center no-padding">
                                                <img className="img-fluid" src={global.ImgUrl + 'shopable/Photo-Guide-App-3.jpg'} alt="guide"></img>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <br></br>
                                            <p>Apabila kamu memiliki pertanyaan lebih lanjut dan belum tercantum di sini, silakan kontak Customer Service kami, ya. Kami akan standby 24 jam untuk kamu. :)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Scaffold>
        );
    }
}

export default CreatorRecruitingScreen;