import React from "react";
import "./ManualCouponInputWidget.css";

export type ManualCouponInputWidgetProps = {
  onClaimClick?: () => void;
  onCodeChange?: (code?: string) => void;
  code?: string;
};

export default class ManualCouponInputWidget extends React.Component<ManualCouponInputWidgetProps> {
  render() {
    const {
      onClaimClick = () => {},
      code = "",
      onCodeChange = () => {},
    } = this.props;

    return (
      <div className="container" id="CheckoutCouponWidget">
        <div
          style={{
            flex: 1,
          }}
        >
          <input
            onChange={(e) => onCodeChange(e.target.value)}
            placeholder="Voucher code"
            style={{
              width: "100%",
              marginRight: 12,
              outline: "none",
              border: "none",
            }}
          />
        </div>

        <button
          style={{
            border: "1px solid #C0C0C0",
            borderRadius: "7px",
            padding: "8px 12px",
            fontWeight: 400,
            flexShrink: 0,
            fontSize: "14px",
          }}
          onClick={() => {
            this.props.onClaimClick?.();
          }}
        >
          Claim
        </button>
      </div>
    );
  }
}
