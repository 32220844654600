import React from "react";
import "./StoreBannerSubSubCateWidget.css";
import ProductSummaryItemModel from "../../../../CommonComponents/Models/ProductSummaryItemModel";
import ProductSummaryItemCellWidget from "../../../../CommonComponents/UI/Cells/ProductSummaryItemCellWidget";
import { standarizeThefepiLink } from "../../../../CommonComponents/Utils";
import { mixpanelManager } from "../../../../Analytics/Mixpanel/Common";
import truncateString from "../../Utils/TruncateString";

export type StoreBannerSubSubCateWidgetProps = {
  bannerImageUrl?: string;
  products?: ProductSummaryItemModel[];
  onRefreshClick?: () => void;
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  caption?: string;
  deeplink?: string;
  renderRefreshButton?: (onRefreshClick?: () => void) => void;
  screenWidth?: number;
};

export default class StoreBannerSubSubCateWidget extends React.Component<StoreBannerSubSubCateWidgetProps> {
  render(): React.ReactNode {
    const {
      isLoading = false,
      title,
      subtitle,
      products = [],
      caption,
      onRefreshClick,
      bannerImageUrl,
      renderRefreshButton,
      screenWidth = 1,
    } = this.props;

    const aspectRatio = 200 / 280;

    return (
      <div id="StoreBannerSubSubCateWidget">
        <div id="StoreBannerSubSubCateWidgetHeader">
          <p
            id="StoreBannerSubSubCateWidgetTitle"
            >
            {title}
          </p>
          <button
            onClick={() => {
              onRefreshClick?.();
            }}
            id="StoreBannerSubSubCateWidgetRefreshButton">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16 7C22.0751 7 27 11.9249 27 18C27 24.0751 22.0751 29 16 29C9.92487 29 5 24.0751 5 18"
                stroke="white"
                stroke-linecap="round"
              />
              <path
                d="M20.3284 3.69459L15.6946 6.93923L18.9392 11.5731"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div id="StoreBannerSubSubCateWidgetBody">
          <div id="StoreBannerSubSubCateWidgetBanner">
            <div
              id="StoreBannerSubSubCateWidgetOverlay"
              onClick={() => {
                if (this.props.deeplink) {
                  mixpanelManager.storeFeedClick({
                    type: "banner",
                    title: this.props.title || "",
                    group_name:
                      window.location.href.match(/Store\/([a-zA-Z]+)/)?.[1] ||
                      "HOME",
                    identifier: "SINGLE_BANNER_PRODUCT_SHUFFLE",
                    screen_name: "Store",
                  });

                  window.location.href = standarizeThefepiLink(
                    this.props.deeplink
                  );
                }
              }}>
              {caption}
            </div>
            <div style={{ height: (screenWidth - 32) * aspectRatio }}>
              <img
                src={bannerImageUrl}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
          <div id="StoreBannerSubSubCateWidgetProductList">
            {isLoading
              ? Array.from({ length: 3 }).map(() => (
                  <div
                    className="ShimmerAnimation"
                    style={{
                      height: `${((screenWidth / 3 - 16) * 4) / 3}px`,
                      borderRadius: `8px`,
                    }}
                  />
                ))
              : [...products].splice(0, 3).map((p) => {
                  return (
                    <ProductSummaryItemCellWidget
                      cellWidth={screenWidth / 3 - 16}
                      badgeUrls={p.badgeUrls}
                      model={{
                        id: p.id,
                        imageUrl: p.imageUrl,
                        price: p.price,
                        brand: p.brand,
                        title: p.title,
                      }}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    );
  }
}
