import "./CreatorSpaceScreen.css";

import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_shop from "../../Assets/Images/ic_shop_icon.svg";
import ic_back from "../../Assets/Images/ic_chevron_left.svg";
import ic_share from "../../Assets/Images/ic_share_box.svg";
import ic_plus from "../../Assets/Images/ic_plus.svg";
import ic_check from "../../Assets/Images/ic_check.svg";
import ic_info from "../../Assets/Images/ic_info_grey.svg";

import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import CreatorSpaceViewModel from "./CreatorSpaceViewModel";
import { useParams } from "react-router-dom";
import CreatorSpaceContentListWidget from "./Widgets/CreatorSpaceContentListWidget";
import CreatorSpaceDescriptionSectionWidget from "./Widgets/CreatorSpaceDescriptionSectionWidget";
import CreatorSpaceTopSectionWidget from "./Widgets/CreatorSpaceTopSectionWidget";
import CreatorSpaceProductListWidget from "./Widgets/CreatorSpaceProductListWidget";
import { isResellerDomain, withSellerId } from "../../CommonComponents/Utils";
import mixpanel from "mixpanel-browser";

function CreatorSpaceScreen(props: any) {
  let { id } = useParams();
  return <CreatorSpacePage {...props} id={id ?? ""} />;
}

class CreatorSpacePage extends BaseUIComponent<
  { id: string; creatorLink?: string },
  CreatorSpaceViewModel
> {
  constructor(props: { id: string }) {
    super(props, new CreatorSpaceViewModel(props.id));
    this.viewModel().loadPreloadingProps();
    window.addEventListener("scroll", () => this.handleScrolling());

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "CreatorSpace",
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => this.handleScrolling());
  }

  render() {
    const spaceDetail = this.viewModel().detail;
    const sellerId = this.viewModel().detail?.sellerProfile.SellerID;
    const contents = this.viewModel().contents;
    const products = this.viewModel().products;
    const isMySpace = this.viewModel().creatorId === this.viewModel().id;
    const isFollowed = spaceDetail?.sellerProfile.isFollowed ?? false;

    return (
      <Scaffold
        showLoadingAndErrorPosition={
          this.viewModel().isPreloadingLoaded ? "on-content-view" : "fullscreen"
        }
        selectedBottomBarItem={isMySpace ? "space" : undefined}
        isShowLoading={this.viewModel().isLoading}
        showErrorModel={this.viewModel().errorMessage}
        showSnackbar={this.viewModel().snackbarMessage}
        creatorId={this.viewModel().creatorId}
        isCreator={this.viewModel().isCreator}
        shareUrlModel={this.viewModel().shareUrlModel}
        showPopUpModel={this.viewModel().popUpModel}
        showDownloadInvitationWidget={true}
      >
        <div id="SpaceScrollableContainer">
          <CreatorSpaceTopSectionWidget
            isMySpace={isMySpace}
            screenWidth={this.state.width}
            spaceDetail={spaceDetail}
            shareLink={() => this.viewModel().onShareLink()}
            isFollowed={isFollowed ?? false}
            followButtonTapped={(isFollowed) =>
              this.viewModel().followCreator(isFollowed)
            }
          />
          <CreatorSpaceDescriptionSectionWidget spaceDetail={spaceDetail} />
          <div id="SpaceCategoryButtonContainer">
            {this.constructCategoryButton()}
          </div>
          {
            this.viewModel().showingItemsType === "content" ? (
              <CreatorSpaceContentListWidget
                contents={contents}
                width={this.state.width}
              />
            ) : (
              <CreatorSpaceProductListWidget
                sellerId={sellerId}
                products={products}
                width={this.state.width}
              />
            ) // TODO:
          }
        </div>
        {this.viewModel().isShowFloatingCategoryButtons && (
          <div id="SpaceFloatingCategoryButtonContainer">
            {this.constructCategoryButton()}
          </div>
        )}
        <div
          id="SpaceNavigationBackground"
          style={{ opacity: `${this.viewModel().navBarTransparency}` }}
        >
          {this.viewModel().navBarTransparency === 1 && (
            <>
              <p id="SpacePageTitle">
                {this.viewModel().detail?.sellerProfile.name ?? ""}
              </p>
              <img src={ic_shop} alt="shop" />
              <div id="SpaceShareAndFollow">
                {!isMySpace && (
                  <button
                    id={
                      isFollowed
                        ? "SpaceFollowButtonFollowed"
                        : "SpaceFollowButtonNonFollowed"
                    }
                    onClick={() => this.viewModel().followCreator(!isFollowed)}
                    key={`${isFollowed}_follow_button`}
                  >
                    {isFollowed ? (
                      <>
                        <img src={ic_check} alt="" />
                        <p>Following</p>
                      </>
                    ) : (
                      <>
                        <img src={ic_plus} alt="" />
                        <p>Follow</p>
                      </>
                    )}
                  </button>
                )}
                <button onClick={() => this.viewModel().onShareLink()}>
                  <img src={ic_share} alt="" />
                </button>
              </div>
            </>
          )}
        </div>
        <div id="SpaceHomeNavContainer">
          {isResellerDomain() ? (
            <button id="SpaceHomeButton" onClick={() => window.history.back()}>
              <img src={ic_back} alt="" />
            </button>
          ) : (
            <button
              id="SpaceHomeButton"
              onClick={() => (window.location.href = "/")}
            >
              <img src={ic_thefepi_f} alt="" />
            </button>
          )}
          <div id="SpaceCartButtonContainer">
            <button
              id="SpaceCartButton"
              onClick={() => {
                window.location.href = isResellerDomain()
                  ? `/shop/${this.props.creatorLink}/Cart`
                  : "/Cart";
              }}
            >
              <img src={ic_cart} alt="" />
              {(this.viewModel().numberInCart ?? 0) > 0 && (
                <div>{this.viewModel().numberInCart}</div>
              )}
            </button>
          </div>
        </div>
      </Scaffold>
    );
  }

  private constructCategoryButton(): JSX.Element {
    const isContentTypeSelected =
      this.viewModel().showingItemsType === "content";

    return (
      <>
        <button
          id={
            isContentTypeSelected
              ? "SpaceSelectedCategoryButton"
              : "SpaceUnselectedCategoryButton"
          }
          onClick={() => this.viewModel().changeItemsCategory("content")}
        >
          Creator's Feed
        </button>
        <div style={{ width: "12px" }} />
        <button
          id={
            isContentTypeSelected
              ? "SpaceUnselectedCategoryButton"
              : "SpaceSelectedCategoryButton"
          }
          onClick={() => this.viewModel().changeItemsCategory("product")}
        >
          Styling Items
        </button>
        <div style={{ flexGrow: 1 }} />
        <button
          style={{ padding: 4 }}
          onClick={() => (window.location.href = "/CreatorIntroduction")}
        >
          <img src={ic_info} alt="info" width={24} height={24} />
        </button>
      </>
    );
  }

  private handleScrolling() {
    const spaceTopContainerElement =
      document.querySelector("#SpaceTopContainer");
    const spaceTopContainerRect =
      spaceTopContainerElement?.getBoundingClientRect();
    let transparencyValue =
      (spaceTopContainerRect?.y ?? 0) / -((this.state.width * 3) / 2 + 16);
    transparencyValue = transparencyValue > 1 ? 1 : transparencyValue;

    this.viewModel().changeNavBarTransparency(transparencyValue);

    const categoryButtonsContainerElement = document.querySelector(
      "#SpaceCategoryButtonContainer"
    );
    const categoryButtonsContainerRect =
      categoryButtonsContainerElement?.getBoundingClientRect();

    this.viewModel().showFloatingCategoryButton(
      (categoryButtonsContainerRect?.y ?? 0) - 68 <= 0
    );
  }
}

export default withSellerId(CreatorSpaceScreen);
