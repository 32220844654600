import React from "react";
import ButtonWidget, {
  ButtonWidgetType,
} from "../../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { ShowErrorModel } from "../../../CommonComponents/UI/Scaffold/Scaffold";
import { standarizeThefepiLink } from "../../../CommonComponents/Utils";
import StoreSectionFeedModel from "../Models/StoreSectionFeedModel";

import "./StoreSectionFeed.css";

import ic_error from "../../../Assets/Images/ic_error_pink.svg";
import ScrollContainer from "react-indiana-drag-scroll";
import timerCalculation from "../Utils/StoreDurationRemainingCalculation";
import ProductSummaryItemCellWidget from "../../../CommonComponents/UI/Cells/ProductSummaryItemCellWidget";
import StoreTopTabbarWidget from "../../CommonComponents/Widgets/StoreTopTabbarWidget";
import { mixpanelManager } from "../../../Analytics/Mixpanel/Common";
import truncateString from "../Utils/TruncateString";

export default class StoreProductListSectionFeedSectionWidget extends React.Component<{
  screenWidth: number;
  model: StoreSectionFeedModel;
  timeRemaining?: number;
  isLoadingProducts: boolean;
  loadProductsError?: ShowErrorModel;
  selectedCategoryId?: string;
  onSelectCategory: (id?: string) => void;
  layout: "HORIZONTAL" | "GRID";
}> {
  render() {
    const model = this.props.model.contents;

    const timerModel = timerCalculation({
      screenWidth: this.props.screenWidth,
      timeRemaining: this.props.timeRemaining,
      totalDurationInSeconds: model.timer?.totalDurationInSeconds,
    });
    const categoryCellWidth =
      (this.props.screenWidth - 32 - 4 * 8) / 4.5 < 72
        ? (this.props.screenWidth - 32 - 3 * 8) / 3.3
        : (this.props.screenWidth - 32 - 4 * 8) / 4.5;
    const cellWidth =
      this.props.layout === "HORIZONTAL"
        ? this.props.screenWidth / 2.8
        : (this.props.screenWidth - 44) / 2;
    const topBorderRadius = model.topCornerRadius
      ? `${model.topCornerRadius}px`
      : undefined;
    const bottomBorderRadius = model.bottomCornerRadius
      ? `${model.bottomCornerRadius}px`
      : undefined;

    return (
      <div
        id="StoreProductListSectionFeedSectionContainer"
        style={{
          position: "relative",
          overflow: "hidden",
          backgroundColor: model.theme === "DARK" ? "black" : undefined,
          borderTopLeftRadius: topBorderRadius,
          borderTopRightRadius: topBorderRadius,
          borderBottomLeftRadius: bottomBorderRadius,
          borderBottomRightRadius: bottomBorderRadius,
        }}>
        {model.backgroundImageUrl && (
          <img
            src={model.backgroundImageUrl}
            alt=""
            style={{
              objectFit: "fill",
              position: "absolute",
              inset: 0,
              maxWidth: "600px",
              height: "100%",
              width: "100%",
            }}
          />
        )}
        <div style={{ zIndex: 1 }}>
          {model.sectionTitle && (
            <div id="StoreProductListSectionFeedSectionTitleContainer">
              {model.topLeadingIconUrl && (
                <div>
                  <ImageWidget imageUrl={model.topLeadingIconUrl} />
                </div>
              )}
              <p
                style={{
                  textAlign:
                    model.isSectionTitleCentered === true ? "center" : "left",
                  color: model.theme === "DARK" ? "white" : undefined,
                }}>
                {" "}
                {this.props.screenWidth >= 600
                  ? model.sectionTitle
                  : truncateString(model.sectionTitle ?? "", 25)}{" "}
              </p>
              {model.seeAllDeeplink && model.seeAllPosition !== "BOTTOM" && (
                <button
                  onClick={() => {
                    mixpanelManager.storeFeedClick({
                      type: "button",
                      title: "see all",
                      group_name:
                        window.location.href.match(/Store\/([a-zA-Z]+)/)?.[1] ||
                        "HOME",
                      identifier:
                        this.props.model.identifier || "STORE_PRODUCT_LIST",
                      screen_name: "Store",
                    });
                    window.location.href = standarizeThefepiLink(
                      model.seeAllDeeplink ?? ""
                    );
                  }}>
                  see all
                </button>
              )}
            </div>
          )}
          {timerModel && (
            <div id="StoreProductListSectionFeedSectionTimerContainer">
              <div>
                <div
                  style={{ width: `${timerModel.remainingTimeDivWidth}px` }}
                />
              </div>
              <p>
                Ends in{" "}
                <strong>
                  {timerModel.days} day(s) / {timerModel.hours} :{" "}
                  {timerModel.minutes} : {timerModel.seconds}
                </strong>
              </p>
            </div>
          )}
          {model.banners.length > 0 && (
            <button
              style={{
                width: `${this.props.screenWidth}px`,
                height: `${(this.props.screenWidth * 5) / 9}px`,
                marginBottom: "12px",
              }}
              onClick={() => {
                mixpanelManager.storeFeedClick({
                  type: "banner",
                  title: "banner_" + model.banners[0].deeplink,
                  group_name:
                    window.location.href.match(/Store\/([a-zA-Z]+)/)?.[1] ||
                    "HOME",
                  identifier:
                    this.props.model.identifier || "STORE_PRODUCT_LIST",
                  screen_name: "Store",
                });
                window.location.href = standarizeThefepiLink(
                  model.banners[0].deeplink ?? ""
                );
              }}>
              <ImageWidget imageUrl={model.banners[0].imageUrl} />
            </button>
          )}
          {model.tabs.length > 0 && (
            <div style={{ marginBottom: 12 }}>
              <StoreTopTabbarWidget
                selectedId={this.props.selectedCategoryId}
                shouldShowBottomBorder={false}
                labels={model.tabs.map((t) => ({
                  label: t.label,
                  id: t.id,
                  onTap: () => {
                    mixpanelManager.tabClick(t.label, "weekly rank");
                    const isSelected = t.id === this.props.selectedCategoryId;
                    if (isSelected) {
                      this.props.onSelectCategory(undefined);
                    } else {
                      this.props.onSelectCategory(t.id);
                    }
                  },
                }))}></StoreTopTabbarWidget>
            </div>
          )}
          {model.categoryItems.length > 0 && (
            <ScrollContainer>
              <div id="StoreProductListSectionFeedSectionCategoriesContainer">
                {model.categoryItems.map((model, index) => {
                  const isSelected = model.id === this.props.selectedCategoryId;

                  if (!model.imageUrl) {
                    return (
                      <button
                        key={`HomeInspirationCategory_${model.id}_${index}`}
                        className={
                          isSelected
                            ? "InspirationCategoryPillActive"
                            : "InspirationCategoryPill"
                        }
                        onClick={() => {
                          mixpanelManager.storeFeedClick({
                            type: "tab",
                            title: model.label,
                            group_name:
                              window.location.href.match(
                                /Store\/([a-zA-Z]+)/
                              )?.[1] || "HOME",
                            identifier:
                              this.props.model.identifier ||
                              "STORE_PRODUCT_LIST",
                            screen_name: "Store",
                          });
                          if (isSelected) {
                            this.props.onSelectCategory(undefined);
                          } else {
                            this.props.onSelectCategory(model.id);
                          }
                        }}>
                        {model.label}
                      </button>
                    );
                  }
                  return (
                    <button
                      key={`HomeOnHypeCategory_${model.id}_${index}`}
                      onClick={() => {
                        mixpanelManager.storeFeedClick({
                          type: "tab",
                          title: model.label,
                          group_name:
                            window.location.href.match(
                              /Store\/([a-zA-Z]+)/
                            )?.[1] || "HOME",
                          identifier:
                            this.props.model.identifier || "STORE_PRODUCT_LIST",
                          screen_name: "Store",
                        });
                        this.props.onSelectCategory(model.id);
                      }}
                      style={{ width: `${categoryCellWidth}px` }}>
                      <div
                        style={{
                          height: `${categoryCellWidth}px`,
                          width: `${categoryCellWidth}px`,
                        }}>
                        <ImageWidget imageUrl={model.imageUrl} />
                      </div>
                      <div>
                        <p
                          id={
                            isSelected
                              ? "StoreProductListSectionFeedSectionSelectedCategory"
                              : "StoreProductListSectionFeedSectionUnselectedCategory"
                          }>
                          {model.label}
                        </p>
                      </div>
                    </button>
                  );
                })}
              </div>
            </ScrollContainer>
          )}
          {this.props.isLoadingProducts && (
            <ScrollContainer>
              <div
                id="StoreProductListSectionFeedSectionProductsContainer"
                style={
                  this.props.layout === "HORIZONTAL"
                    ? {}
                    : {
                        flexFlow: "row wrap",
                      }
                }>
                {Array.from({ length: 4 }).map(() => {
                  return (
                    <div>
                      <div
                        className="ShimmerAnimation"
                        style={{
                          width: `${cellWidth}px`,
                          height: `${(cellWidth * 4) / 3}px`,
                          borderRadius: `8px`,
                        }}
                      />
                      <div style={{ height: "2px" }} />
                      <div
                        className="ShimmerAnimation"
                        style={{
                          width: `${cellWidth / 2}px`,
                          height: `16px`,
                          borderRadius: `8px`,
                        }}
                      />
                      <div style={{ height: "2px" }} />
                      <div
                        className="ShimmerAnimation"
                        style={{
                          width: `${cellWidth}px`,
                          height: `16px`,
                          borderRadius: `8px`,
                        }}
                      />
                      <div style={{ height: "2px" }} />
                      <div
                        className="ShimmerAnimation"
                        style={{
                          width: `${(cellWidth * 3) / 4}px`,
                          height: `14px`,
                          borderRadius: `7px`,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </ScrollContainer>
          )}
          {this.props.loadProductsError && (
            <div
              id="StoreProductListSectionFeedSectionLoadProductErrorContainer"
              style={{ height: `${cellWidth + 52}px` }}>
              <img src={ic_error} alt="" />
              <p>We found a problem, please try to reload.</p>
              <ButtonWidget
                buttonType={ButtonWidgetType.primary}
                action={() =>
                  this.props.loadProductsError?.onRetryButtonTapped()
                }
                buttonHeight={32}>
                Retry
              </ButtonWidget>
            </div>
          )}
          {model.products.length > 0 && (
            <ScrollContainer>
              <div
                id="StoreProductListSectionFeedSectionProductsContainer"
                style={
                  this.props.layout === "HORIZONTAL"
                    ? {}
                    : {
                        flexFlow: "row wrap",
                      }
                }>
                {model.products.map((product, index) => {
                  return (
                    <ProductSummaryItemCellWidget
                      key={`HomeOnHypeProducts_${product.id}_${index}`}
                      model={product}
                      cellWidth={cellWidth}
                      theme={model.theme}
                      badgeUrls={product.badgeUrls}
                    />
                  );
                })}
              </div>
            </ScrollContainer>
          )}
          {model.seeAllDeeplink && model.seeAllPosition === "BOTTOM" && (
            <div style={{ padding: "32px 16px 0px 16px" }}>
              <ButtonWidget
                action={() =>
                  (window.location.href = standarizeThefepiLink(
                    model.seeAllDeeplink ?? ""
                  ))
                }
                buttonType={ButtonWidgetType.secondary}>
                See all
              </ButtonWidget>
            </div>
          )}
          {model.cta && (
            <div style={{ padding: "32px 16px 0px 16px" }}>
              <ButtonWidget
                action={() =>
                  (window.location.href = standarizeThefepiLink(
                    model.cta?.deeplink ?? ""
                  ))
                }
                backgroundColor={model.cta.backgroundColorHex}
                titleColor={model.cta.textColorHex}>
                {model.cta.title}
              </ButtonWidget>
            </div>
          )}
        </div>
      </div>
    );
  }
}
