import React from "react";
import "./StoreSectionFeed.css";
import StoreSectionFeedModel from "../Models/StoreSectionFeedModel";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { standarizeThefepiLink } from "../../../CommonComponents/Utils";
import { mixpanelManager } from "../../../Analytics/Mixpanel/Common";

export type StoreGridIconListWidgetProps = {
  model?: StoreSectionFeedModel;
  onGridItemClick?: (url?: string) => void;
};

export class StoreGridIconListWidget extends React.Component<StoreGridIconListWidgetProps> {
  render() {
    // console.log("puropsu", this.props);
    if (!this.props.model) return null;

    return (
      <div id="StoreGridIconListWidget" className="GridContainer">
        {this.props.model.contents.icons.map((icon, index) => {
          return (
            <button
              onClick={() => {
                mixpanelManager.storeFeedClick({
                  type: "button",
                  title: icon.label,
                  group_name:
                    window.location.href.match(/Store\/([a-zA-Z]+)/)?.[1] ||
                    "HOME",
                  identifier: this.props.model?.identifier || "",
                  screen_name: "Store",
                });
                this.props.onGridItemClick?.(
                  standarizeThefepiLink(icon.deeplink)
                );
              }}
              key={`button_${index}`}
              className="GridItemContainer"
            >
              <div className="GridItemImage">
                <ImageWidget imageUrl={icon.imageUrl} />
              </div>

              <div className="GridItemLabel">{icon.label}</div>
            </button>
          );
        })}
      </div>
    );
  }
}
