import React from "react";
import { useParams } from "react-router-dom";
import ViewController from "../../CommonComponents/Base/ViewController";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import ReviewListViewModel from "./ReviewListViewModel";
import ReviewListWidget from "./Widgets/ReviewList/ReviewListWidget";
import ReviewSummaryWidget from "./Widgets/ReviewSummary/ReviewSummaryWidget";
import mixpanel from "mixpanel-browser";
import { isResellerDomain } from "../../CommonComponents/Utils";

export default function ReviewListViewControllerScreen() {
  let { productId } = useParams();
  return <ReviewListViewController productId={productId ?? ""} />;
}

export type ReviewListViewControllerProps = {
  productId?: string;
};

export class ReviewListViewController extends ViewController<
  ReviewListViewControllerProps,
  ReviewListViewModel
> {
  constructor(args: ReviewListViewControllerProps) {
    super(
      {},
      new ReviewListViewModel({
        productId: args.productId,
      })
    );

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "Review",
    });
  }

  renderTopBar() {
    return (
      <button
        onClick={() => {
          window.history.back();
        }}
        style={{ height: 52, display: "flex", alignItems: "center" }}
      >
        <svg
          style={{ marginRight: 12 }}
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.121 0.500429C6.97021 0.506009 6.82774 0.566692 6.7241 0.669485L0.373761 6.62027C0.358134 6.63082 0.343082 6.64211 0.328659 6.65408C0.141395 6.87103 0.160928 7.18586 0.373761 7.38102L6.71508 13.3318C6.85909 13.4737 7.07367 13.5316 7.27616 13.4832C7.47864 13.4348 7.63741 13.2877 7.69129 13.0985C7.74517 12.9093 7.68576 12.7076 7.53594 12.571L2.16881 7.54162H17.2685C17.4767 7.54438 17.6703 7.44188 17.7753 7.27337C17.8802 7.10485 17.8802 6.89643 17.7753 6.72792C17.6703 6.55941 17.4767 6.4569 17.2685 6.45966H2.16881L7.54496 1.43024C7.71727 1.27431 7.76895 1.03488 7.67483 0.828459C7.58071 0.622042 7.36035 0.491524 7.121 0.500429Z"
            fill="black"
            stroke="black"
            stroke-width="0.2"
            stroke-linejoin="round"
          />
        </svg>
        <p
          style={{
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Review
        </p>
      </button>
    );
  }

  render(): React.ReactNode {
    return (
      <Scaffold backgroundColor="#f2f2f2" topBar={this.renderTopBar()}>
        <div style={{ backgroundColor: "#fff", padding: 24 }}>
          <ReviewSummaryWidget
            score={this.viewModel.allReviewsResult?.reviewSummary.starAverage}
            maxScore={5}
            reviewCount={this.viewModel.allReviewsResult?.totalReviews}
            starDistribution={{
              "1":
                this.viewModel.allReviewsResult?.reviewSummary.numberOfEachStar
                  .oneStar || 0,
              "2":
                this.viewModel.allReviewsResult?.reviewSummary.numberOfEachStar
                  .twoStar || 0,
              "3":
                this.viewModel.allReviewsResult?.reviewSummary.numberOfEachStar
                  .threeStar || 0,
              "4":
                this.viewModel.allReviewsResult?.reviewSummary.numberOfEachStar
                  .fourStar || 0,
              "5":
                this.viewModel.allReviewsResult?.reviewSummary.numberOfEachStar
                  .fiveStar || 0,
            }}
          />
        </div>
        <SizedDiv height={8} />
        <ReviewListWidget
          reviews={this.viewModel.allReviewsResult?.reviews.map((r) => ({
            date: r.timeLabel,
            images: r.imageUrls,
            profilePicture: r.profileImageUrl,
            name: r.username,
            review: r.reviewLabel,
            problems: r.problems,
            score: r.star,
          }))}
        />
      </Scaffold>
    );
  }
}
