import React from "react";
import CartResponse from "../Models/CartResponse";
import { convertToRupiah } from "../../../CommonComponents/Utils";

type CartItemsWidgetProps = {
  cartData: CartResponse | null;
  onPlusMinusClicked: (
    flag: number,
    bagItemId: string,
    quantity: number
  ) => void;
  onCheckClicked: (bagItemId: string) => void;
};

export default class CartItemsWidget extends React.Component<CartItemsWidgetProps> {
  render() {
    const cartData = this.props.cartData;
    console.log({ cartData });
    return (
      <div className="row ItemWidgetSection">
        {cartData?.items.length === 0 ? (
          <div className="col text-center">
            <img
              width="60"
              src={global.ImgUrl + "shopable/ic-nostylingitem@3x.png"}
              alt="gambar1"></img>
            <h5>
              <b>No Item Yet</b>
            </h5>
            <p style={{ color: "grey", fontSize: "13px" }}>
              <b>
                There are no item in my bag <br></br> Tap the button below to
                check styling item.
              </b>
            </p>
          </div>
        ) : (
          <div className="col">
            {cartData?.items.map((items, index) => (
              <div className="ItemDiv" key={index}>
                <div className="row">
                  <div className="col-1 round paddingtop20p">
                    <label className="containers">
                      <input
                        className="bag_item_id"
                        type="checkbox"
                        defaultChecked={true}
                        value={items.bagItemId}
                        onChange={() =>
                          this.props.onCheckClicked(items.bagItemId)
                        }></input>
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div id="LineItem" className="col-11">
                    <div className="LineItemContainer">
                      <div className="row">
                        <div
                          className="col-5"
                          onClick={() => {
                            window.location.href = `/Product/${items.productId}`;
                          }}>
                          <img
                            alt=""
                            className="LineItemImage"
                            src={items.imageUrl}></img>
                        </div>
                        <div className="col-7">
                          <div
                            onClick={() => {
                              window.location.href = `/Product/${items.productId}`;
                            }}>
                            <p className="LineItemBrand">{items.name}</p>
                            <p className="LineItemDescription">
                              {items.itemDescription}
                            </p>
                          </div>
                          {items.price.discount !== 0 ? (
                            <p className="LineItemPrice">
                              <span className="LineItemCompareAtPrice">
                                {convertToRupiah(items.price.realPrice)}
                              </span>
                              <span className="LineItemDiscountPercetage">
                                {items.price.discount}%
                              </span>
                              <br></br>
                              {convertToRupiah(items.price.netPrice)}
                            </p>
                          ) : (
                            <p className="LineItemPrice">
                              {convertToRupiah(items.price.netPrice)}
                            </p>
                          )}
                          <p className="LineItemVariant">{items.attribute}</p>
                          <ul id="qty_cart" className="ea list-inline">
                            <li className="list-inline-item plusminus minusBtn">
                              <button
                                onClick={() =>
                                  this.props.onPlusMinusClicked(
                                    1,
                                    items.bagItemId,
                                    items.quantity
                                  )
                                }
                                className="minus">
                                -
                              </button>
                            </li>
                            <li className="list-inline-item">
                              <input
                                readOnly
                                className="box_input"
                                type="number"
                                width="50"
                                name="buy_ea"
                                value={items.quantity}></input>
                            </li>
                            <li className="list-inline-item plusminus addBtn">
                              <button
                                onClick={() =>
                                  this.props.onPlusMinusClicked(
                                    2,
                                    items.bagItemId,
                                    items.quantity
                                  )
                                }
                                className="plus">
                                +
                              </button>
                            </li>
                          </ul>
                          <p className="weight">{items.weight}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
