import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import CouponViewModel from "./CouponViewModel";
import ButtonWidget, {
  ButtonWidgetType,
} from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import { Navigate, useNavigate } from "react-router-dom";
import CouponSheet from "./Widgets/CouponSheet";

import "./Coupon.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_back from "../../Assets/Images/ic_chevron_left.svg";
import ViewController from "../../CommonComponents/Base/ViewController";
import ManualCouponInputWidget from "./Widgets/ManualCouponInputWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import ic_chevron_left from "../../Assets/Images/ic_chevron_left.svg";
import { isResellerDomain } from "../../CommonComponents/Utils";
import mixpanel from "mixpanel-browser";

const GoBackButton = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        navigate(-1);
      }}
    >
      <img alt="assets" id="pageBackButton" src={ic_chevron_left}></img>
    </button>
  );
};
export default class CouponScreen extends ViewController<{}, CouponViewModel> {
  constructor(props: {}) {
    super(props, new CouponViewModel());
    this.viewModel.loadPreloadingProps();

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "Coupon",
    });
  }

  handleUseCoupon = (couponId: string, couponName: string) => {
    this.viewModel.handleUseCoupon(couponId, couponName);
    this.viewModel.emit(() => {});
  };

  handleShowCouponDetail = (index: number) => {
    this.viewModel.handleShowCouponDetail(index);
  };

  Apply = () => {
    if (localStorage.point !== undefined) {
      localStorage.removeItem("point");
    }
    window.location.href = "/Checkout";
  };

  render() {
    const couponData = this.viewModel.couponData;
    const DeeplinkHeight = 46;
    const fixedHeight = 72;

    if (localStorage.getItem("selectedBagItem") === null) {
      return <Navigate to="/" />;
    }
    return (
      <Scaffold
        topBar={
          <>
            <GoBackButton />
            <p id="pageTitle">Voucher</p>
          </>
        }
        isShowLoading={this.viewModel.isLoading}
        showSnackbar={this.viewModel.snackbarMessage}
        showErrorModel={this.viewModel.errorModel}>
        <div id="Coupon" className="container">
          <p className="font14 margin0">
            <b>Voucher</b>
          </p>
          <SizedDiv height={12} />
          <ManualCouponInputWidget
            onClaimClick={() => {
              this.viewModel.claimCoupon();
            }}
            code={this.viewModel.enteredCouponCode}
            onCodeChange={(code?: string) =>
              this.viewModel.handleEnteredCouponCodeChange(code)
            }
          />

          <div className="row">
            <div className="col">
              <br></br>
              <p className="font14">Available for this purchase</p>

              {couponData?.coupons.map((items, index) => (
                <div className="box_white_cart" key={index}>
                  <div
                    className="row"
                    style={{
                      paddingRight: "0 !important",
                    }}>
                    {items.imageUrl ? (
                      <div className="couponImage text-center">
                        <img
                          id={"image-" + items.id}
                          width="115"
                          src={items.imageUrl}
                          alt={items.title}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src =
                              global.ImgUrl + "shopable/imgCouponBig@3x.png";
                          }}></img>
                      </div>
                    ) : (
                      <div className="col-3 text-center couponNoImage">
                        <img
                          width="50"
                          src={global.ImgUrl + "shopable/imgCouponBig@3x.png"}
                          alt="img_coupon"
                          style={{
                            margin: "auto",
                          }}></img>
                      </div>
                    )}
                    {/* <div className="col-1 text-start"></div> */}
                    <div className="couponBody">
                      {/* <div className="couponSubBody"> */}
                      <p className="couponTitle">
                        <b>{items.title}</b>
                      </p>
                      <p className="couponDesc">{items.subtitle}</p>
                      {/* </div> */}
                      <p className="couponDuration">{items.period}</p>
                      <button
                        // onClick={() => this.handleViewCouponDetail(items.id)}
                        className="couponDetailLink"
                        onClick={() => {
                          this.handleShowCouponDetail(index);
                        }}>
                        Detail
                      </button>
                    </div>
                    <div className="col-2 radio text-end">
                      <input
                        id={"couponRadio" + index}
                        defaultChecked={
                          localStorage.couponId === items.id && true
                        }
                        type="radio"
                        name="radio_coupon"
                        onChange={() =>
                          this.handleUseCoupon(items.id, items.title)
                        }></input>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {this.viewModel.couponDetailsData[0] ? (
            <CouponSheet
              title={
                this.viewModel.couponDetailsData[
                  this.viewModel.couponDetailClickedIndex
                ].title
              }
              description={
                this.viewModel.couponDetailsData[
                  this.viewModel.couponDetailClickedIndex
                ].description
              }
              tnc={
                this.viewModel.couponDetailsData[
                  this.viewModel.couponDetailClickedIndex
                ].tnc
              }
              isVisible={this.viewModel.shouldShowCouponDetailSheet}
              onBackClick={() => this.viewModel.toggleSheet()}
              onUnderlayClick={() => this.viewModel.toggleSheet()}
              onCloseClick={() => this.viewModel.toggleSheet()}
              onSelectClick={() => {
                document
                  .getElementById(
                    "couponRadio" + this.viewModel.couponDetailClickedIndex
                  )
                  ?.click();
                this.viewModel.toggleSheet();
              }}
            />
          ) : (
            ""
          )}
          <div
            id="CartTopGradient"
            className="row"
            style={{ bottom: `${DeeplinkHeight + fixedHeight - 75}px` }}
          />
          <div className="row fixed">
            {/* <div
              className="col-2 text-center paddingtop20"
              data-bs-toggle="modal"
              data-bs-target="#ChatModal"
            >
              <img
                width="20"
                className="img-fluid"
                src={global.ImgUrl + "shopable/ic-detail-chat@2x.png"}
                alt="gambar1"
              ></img>
            </div> */}
            <div className="col-12 text-center paddingtop10">
              <ButtonWidget
                buttonType={ButtonWidgetType.primary}
                isLoading={this.viewModel.isLoadingButtonSubmit}
                isEnabled={
                  localStorage.getItem("selectedBagItem") === null
                    ? false
                    : localStorage.getItem("use_coupon") === "0"
                    ? false
                    : true
                }
                width="calc(100% - 6px)"
                action={() => {
                  this.Apply();
                }}>
                Apply
              </ButtonWidget>
            </div>
          </div>
          <div
            id="SpaceNavigationBackground"
            className="row"
            style={{ opacity: `${this.viewModel.navBarTransparency}` }}></div>
          <div className="row floatingButton">
            {isResellerDomain() ? (
              <button
                id="SpaceHomeButton"
                onClick={() => window.history.back()}>
                <img src={ic_back} alt="" />
              </button>
            ) : (
              <button
                id="SpaceHomeButton"
                onClick={() => (window.location.href = "/")}>
                <img src={ic_thefepi_f} alt="" />
              </button>
            )}
            <button
              id="SpaceCartButton"
              onClick={() => {
                window.location.href = "/Cart";
              }}>
              <img src={ic_cart} alt="" />
              {(this.viewModel.numberInCart ?? 0) > 0 && (
                <div>{this.viewModel.numberInCart}</div>
              )}
            </button>
          </div>
          <ChannelChatPopup />
        </div>
      </Scaffold>
    );
  }
}
